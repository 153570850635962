import React from 'react';
import { useDispatch } from 'react-redux';
import acMeAffiliate from '../../store/actions/affiliateAuth/meAffiliate';

export default function AffiliateInitApplication() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(acMeAffiliate() as any);
  }, []);

  return null;
}
