import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { sIsAffiliateAuthenticated } from '../../../../store/selectors/affiliateAuth';
import AppRoutes from '../../../../constants/appRoutes';

export function RequireAuthAffiliate({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const isAuthenticated = useSelector(sIsAffiliateAuthenticated);

  if (isAuthenticated) {
    return children;
  }

  return (
    <Navigate
      to={AppRoutes.affiliateLogin}
      state={{ from: location }}
      replace
    />
  );
}
