import { useSelector } from 'react-redux';
import {
  Button,
  Popconfirm,
  Space,
  TablePaginationConfig,
  Tooltip,
} from 'antd';
import React from 'react';
import { useImmer } from 'use-immer';
import { stringify } from 'query-string';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { sAuthUser } from '../../store/selectors/auth';
import api from '../../service';
import ApiRoutes from '../../constants/apiRoutes';
import { FiniteStates } from '../../constants/finiteStates';
import { urlHelper } from '../../utils/urlHelper';
import AppRoutes from '../../constants/appRoutes';

interface InitAffiliate {
  id?: number;
  username: string;
  password: string;
  randomPercentage: number;
}

const initialAffiliate: InitAffiliate = {
  username: '',
  password: '',
  randomPercentage: 100,
};

export function useAffiliates() {
  const authUser = useSelector(sAuthUser);

  const [data, updateData] = useImmer({
    items: [],
    meta: {
      totalPages: 0,
      totalItems: 0,
      itemCount: 10,
      currentPage: 1,
      itemsPerPage: 10,
    },
    sorter: {
      order: 'descend',
      field: 'created_at',
    },
    state: FiniteStates.IDLE,
    open: false,
    errors: undefined,
    confirmLoading: false,
    affiliate: initialAffiliate,
  });

  const showModal = () => {
    updateData((draft) => {
      draft.open = true;
    });
  };

  const handleCancel = () => {
    updateData((draft) => {
      draft.open = false;
      draft.affiliate = initialAffiliate;
    });
  };

  async function reload() {
    try {
      updateData((draft) => {
        draft.state = FiniteStates.LOADING;
      });
      let res: any;

      let order: any = {};
      if ((data.sorter as any).order === 'descend') {
        order = {
          order: 'DESC',
          field: (data.sorter as any).field,
        };
      } else if ((data.sorter as any).order === 'ascend') {
        order = {
          order: 'ASC',
          field: (data.sorter as any).field,
        };
      }
      const params = {
        ...data.meta,
        ...order,
      };

      res = await api.get(`${ApiRoutes.getAffiliates}?${stringify(params)}`);
      res = {
        ...res,
        data: {
          ...res.data,
          items: res.data.items,
        },
      };

      updateData((draft) => {
        draft.meta = res.data.meta;
        draft.items = res.data.items;
        draft.state = FiniteStates.SUCCESS;
      });
    } catch (e) {
      updateData((draft) => {
        draft.state = FiniteStates.FAILURE;
      });
    }
  }

  const handleOk = async () => {
    updateData((draft) => {
      draft.confirmLoading = true;
    });

    try {
      if ((data.affiliate as InitAffiliate).id) {
        await api.post(ApiRoutes.updateAffiliate, {
          id: data.affiliate.id,
          randomPercentage: data.affiliate.randomPercentage,
          password: data.affiliate.password,
        });
      } else {
        await api.post(ApiRoutes.createAffiliate, data.affiliate);
      }

      updateData((draft) => {
        draft.open = false;
        draft.errors = undefined;
        draft.affiliate = initialAffiliate;
      });

      reload().catch(console.error);
    } catch (e: any) {
      if (e.response?.data?.error || e.response?.data?.message) {
        updateData((draft) => {
          draft.errors = {
            error: e.response?.data.error,
            messages: e.response?.data.message,
          } as any;
        });
      } else {
        console.error(e);
      }
    }

    updateData((draft) => {
      draft.confirmLoading = false;
    });
  };

  function getAffiliate(affiliate: InitAffiliate) {
    updateData((draft) => {
      draft.affiliate = {
        username: affiliate.username,
        password: affiliate.password,
        randomPercentage: affiliate.randomPercentage,
        id: affiliate.id,
      } as any;

      draft.open = true;
    });
  }

  const [columns] = useImmer<any[]>([
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      render: (value: any, row: any) => (
        <Link
          to={urlHelper(AppRoutes.affiliateInfo, {
            id: value,
            username: row.username,
          })}
        >
          {value}
        </Link>
      ),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      sorter: false,
    },
    {
      title: 'Random Percentage',
      dataIndex: 'randomPercentage',
      key: 'randomPercentage',
      sorter: false,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      ellipsis: { showTitle: true },
      key: 'createdAt',
      sorter: true,
      render: (value: any) =>
        value ? dayjs(value).format('YYYY-MM-DD HH:MM') : '',
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      ellipsis: { showTitle: true },
      key: 'updatedAt',
      sorter: true,
      render: (value: any) =>
        value ? dayjs(value).format('YYYY-MM-DD HH:MM') : '',
    },
    {
      title: 'Actions',
      key: 'actions',
      ellipsis: { showTitle: true },
      fixed: 'right',
      render: (row: any, _: any, index: number) => (
        <Space>
          <div>
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => getAffiliate(row)}
            />
          </div>
          <Popconfirm
            okText="Yes"
            cancelText="No"
            placement="bottom"
            title="Delete Affiliate"
            onConfirm={async () => {
              try {
                await api.post(ApiRoutes.deleteAffiliate, { id: row.id });
                updateData((draft) => {
                  draft.items = draft.items.filter((val, i) => i !== index);
                });
              } catch (e) {
                console.error(e);
              }
            }}
          >
            <Tooltip title="Delete Affiliate">
              <Button danger type="primary" size="small" shape="circle">
                <CloseOutlined />
              </Button>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]);

  React.useEffect(() => {
    (async () => {
      try {
        updateData((draft) => {
          draft.state = FiniteStates.LOADING;
        });
        let res: any;

        let order: any = {};
        if ((data.sorter as any).order === 'descend') {
          order = {
            order: 'DESC',
            field: (data.sorter as any).field,
          };
        } else if ((data.sorter as any).order === 'ascend') {
          order = {
            order: 'ASC',
            field: (data.sorter as any).field,
          };
        }
        const params = {
          ...data.meta,
          ...order,
        };

        res = await api.get(`${ApiRoutes.getAffiliates}?${stringify(params)}`);
        res = {
          ...res,
          data: {
            ...res.data,
            items: res.data.items,
          },
        };

        updateData((draft) => {
          draft.meta = res.data.meta;
          draft.items = res.data.items;
          draft.state = FiniteStates.SUCCESS;
        });
      } catch (e) {
        updateData((draft) => {
          draft.state = FiniteStates.FAILURE;
        });
      }
    })();
  }, [data.sorter, data.meta.currentPage, data.meta.itemsPerPage]);

  function onChange(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) {
    if (sorter) {
      updateData((draft) => {
        draft.sorter = {
          order: (sorter as any).order,
          field: (sorter as any).field,
        };
      });
    }
  }

  return {
    data,
    columns,
    authUser,
    onChange,
    handleOk,
    showModal,
    updateData,
    handleCancel,
    loading: [FiniteStates.IDLE, FiniteStates.LOADING].includes(data.state),
  };
}
