import React from 'react';
import { useDispatch } from 'react-redux';
import acMe from '../../../../store/actions/auth/me';

export default function InitApplication() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(acMe() as any);
  }, []);

  return null;
}
