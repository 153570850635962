import React from 'react';
import { Breadcrumb, Card, Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useGameQuestions } from './useGameQuestions';
import AppRoutes from '../../constants/appRoutes';

export default function GameQuestions() {
  const { loading, data, columns } = useGameQuestions();

  return (
    <>
      <Card>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={AppRoutes.games}>Games</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Typography.Text strong>Sender: </Typography.Text>
            <Typography.Text>
              {(data.game as any)?.sender?.username}
            </Typography.Text>
            <Typography.Text> | </Typography.Text>
            <Typography.Text strong>Receiver: </Typography.Text>
            <Typography.Text>
              {(data.game as any)?.receiver?.username}
            </Typography.Text>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Card>
      <Card>
        <Table
          rowKey="id"
          loading={loading}
          columns={columns}
          pagination={false}
          scroll={{ x: true }}
          dataSource={data?.items || []}
        />
      </Card>
    </>
  );
}
