import { acLogout, acUpsertAuthUser } from './index';
import { Globals } from '../../../types/globals';
import ApiRoutes from '../../../constants/apiRoutes';

const acMe =
  (): Globals.ThunkAction =>
  async (dispatch, getState, { api }) => {
    const {
      hasError,
      body: { data },
    } = await api.get(ApiRoutes.me);
    if (!hasError) {
      dispatch(acUpsertAuthUser(data));
    } else {
      dispatch(acLogout());
    }
  };

export default acMe;
