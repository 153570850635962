import {
  applyMiddleware,
  compose,
  legacy_createStore as createStore,
} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { Globals } from '../types/globals';
import { Api } from './api';
import { ApiAffiliate } from './api/affiliate';

export default function configureStore() {
  let composeEnhancers: typeof compose = compose;

  if (process.env.NODE_ENV === 'development') {
    composeEnhancers =
      (window as Globals.Window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ||
      compose;
  }

  const api = new Api();
  const apiAffiliate = new ApiAffiliate();

  const store = createStore(
    rootReducer(),
    composeEnhancers(
      applyMiddleware(
        thunk.withExtraArgument({
          api,
          apiAffiliate,
        })
      )
    )
  );

  if (process.env.NODE_ENV === 'development') {
    (window as Globals.Window).store = store;
  }

  return store;
}
