import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import acFetchAffiliateToken from '../../store/actions/affiliateAuth/fetchAffiliateToken';
import {
  sIsAffiliateAuthenticated,
  sAffiliateMessage,
} from '../../store/selectors/affiliateAuth';
import { acSetAffiliateMessage } from '../../store/actions/affiliateAuth';
import { Forms } from '../../types/forms';

const defaultValues: Partial<Forms.Login> = {
  username: '',
  password: '',
};

export default function useAffiliateLogin() {
  const { handleSubmit, ...formRest } = useForm<Forms.Login>({
    mode: 'onSubmit',
    defaultValues,
  });

  const dispatch = useDispatch();
  const message = useSelector(sAffiliateMessage);
  const isAuthenticated = useSelector(sIsAffiliateAuthenticated);

  function afterClose() {
    dispatch(acSetAffiliateMessage(undefined));
  }

  const submit = React.useCallback(
    (formValues: any) => dispatch(acFetchAffiliateToken(formValues) as any),
    [dispatch]
  );

  return {
    onSubmit: handleSubmit(submit),
    afterClose,
    isAuthenticated,
    message,
    ...formRest,
  };
}
