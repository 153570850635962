import { useSelector } from 'react-redux';
import {
  Button,
  Popconfirm,
  Space,
  TablePaginationConfig,
  Tag,
  Tooltip,
  Image,
} from 'antd';
import React from 'react';
import dayjs from 'dayjs';
import { useImmer } from 'use-immer';
import { stringify } from 'query-string';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { sAuthUser } from '../../store/selectors/auth';
import api from '../../service';
import ApiRoutes from '../../constants/apiRoutes';
import { FiniteStates } from '../../constants/finiteStates';
import { s3PublicURL } from '../../constants';
import { ReportStatuses } from '../../constants/reportStatuses';
import { urlHelper } from '../../utils/urlHelper';
import AppRoutes from '../../constants/appRoutes';

export function useContacts() {
  const authUser = useSelector(sAuthUser);

  const [data, updateData] = useImmer({
    items: [],
    meta: {
      totalPages: 0,
      totalItems: 0,
      itemCount: 10,
      currentPage: 1,
      itemsPerPage: 10,
    },
    sorter: {
      order: 'descend',
      field: 'created_at',
    },
    filters: {
      status: [ReportStatuses.PENDING, ReportStatuses.SEEN],
    },
    state: FiniteStates.IDLE,
  });

  const [columns] = useImmer<any[]>([
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: 'User',
      dataIndex: 'user',
      ellipsis: { showTitle: true },
      key: 'user',
      render: (value: any) => (
        <Link to={urlHelper(AppRoutes.userInfo, { id: value?.id })}>
          {value?.username}
        </Link>
      ),
    },
    {
      title: 'Text',
      dataIndex: 'text',
      key: 'text',
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (value: any) => {
        if (!value) {
          return <span />;
        }
        return <Image src={s3PublicURL + value} width={50} />;
      },
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        {
          text: 'Pending',
          value: ReportStatuses.PENDING,
        },
        {
          text: 'Seen',
          value: ReportStatuses.SEEN,
        },
      ],
      render: (value: any) => {
        switch (value) {
          case ReportStatuses.PENDING: {
            return (
              <Tag icon={<MinusCircleOutlined />} color="default">
                pending
              </Tag>
            );
          }
          case ReportStatuses.SEEN: {
            return (
              <Tag icon={<CheckCircleOutlined />} color="success">
                seen
              </Tag>
            );
          }
          default: {
            return (
              <Tag icon={<ExclamationCircleOutlined />} color="warning">
                warning
              </Tag>
            );
          }
        }
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      ellipsis: { showTitle: true },
      key: 'createdAt',
      sorter: true,
      render: (value: any) =>
        value ? dayjs(value).format('YYYY-MM-DD HH:MM') : '',
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      ellipsis: { showTitle: true },
      key: 'updatedAt',
      sorter: true,
      render: (value: any) =>
        value ? dayjs(value).format('YYYY-MM-DD HH:MM') : '',
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (row: any, _: any, index: number) => (
        <Space>
          {row.status === ReportStatuses.PENDING && (
            <Popconfirm
              okText="Yes"
              cancelText="No"
              placement="bottom"
              title="Set to SEEN"
              onConfirm={async () => {
                try {
                  await api.post(ApiRoutes.updateContact, { id: row.id });
                  updateData((draft) => {
                    (draft.items[index] as any).status = ReportStatuses.SEEN;
                  });
                } catch (e) {
                  console.error(e);
                }
              }}
            >
              <Tooltip title="Seen">
                <Button type="primary" size="small" shape="circle">
                  <CheckOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>
          )}
          <Popconfirm
            okText="Yes"
            cancelText="No"
            placement="bottom"
            title="Delete Contact"
            onConfirm={async () => {
              try {
                await api.post(ApiRoutes.deleteContact, { id: row.id });
                updateData((draft) => {
                  draft.items = draft.items.filter((val, i) => i !== index);
                });
              } catch (e) {
                console.error(e);
              }
            }}
          >
            <Tooltip title="Delete Contact">
              <Button danger type="primary" size="small" shape="circle">
                <CloseOutlined />
              </Button>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]);

  React.useEffect(() => {
    (async () => {
      try {
        updateData((draft) => {
          draft.state = FiniteStates.LOADING;
        });

        let res: any;

        let order: any = {};
        if ((data.sorter as any).order === 'descend') {
          order = {
            order: 'DESC',
            field: (data.sorter as any).field,
          };
        } else if ((data.sorter as any).order === 'ascend') {
          order = {
            order: 'ASC',
            field: (data.sorter as any).field,
          };
        }
        const params = {
          ...data.meta,
          status: data.filters.status ? data.filters.status.join(',') : null,
          ...order,
        };

        res = await api.get(`${ApiRoutes.contacts}?${stringify(params)}`);
        res = {
          ...res,
          data: {
            ...res.data,
            items: res.data.items,
          },
        };

        updateData((draft) => {
          draft.meta = res.data.meta;
          draft.items = res.data.items;
          draft.state = FiniteStates.SUCCESS;
        });
      } catch (e) {
        updateData((draft) => {
          draft.state = FiniteStates.FAILURE;
        });
      }
    })();
  }, [
    data.sorter,
    data.filters,
    data.meta.currentPage,
    data.meta.itemsPerPage,
  ]);

  function onChange(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) {
    if (sorter) {
      updateData((draft) => {
        draft.sorter = {
          order: (sorter as any).order,
          field: (sorter as any).field,
        };
      });
    }

    updateData((draft) => {
      draft.filters.status = (filters?.status as any) || [];
    });
  }

  return {
    data,
    columns,
    authUser,
    onChange,
    updateData,
    loading: [FiniteStates.IDLE, FiniteStates.LOADING].includes(data.state),
  };
}
