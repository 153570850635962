import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Modal,
  Table,
} from 'antd';
import ReactJson from 'react-json-view';
import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { useBotQuestions } from './useBotQuestions';
import AppRoutes from '../../constants/appRoutes';

export default function BotQuestions() {
  const {
    data,
    columns,
    loading,
    handleOk,
    onChange,
    showModal,
    updateData,
    handleCancel,
  } = useBotQuestions();
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <Card>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={AppRoutes.bots}>Bots</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{id}</Breadcrumb.Item>
          <Breadcrumb.Item>Questions</Breadcrumb.Item>
        </Breadcrumb>
      </Card>
      <br />
      <Card>
        <div style={{ marginBottom: 16 }}>
          <Button onClick={showModal} icon={<PlusOutlined />}>
            Create Question
          </Button>
          <Modal
            title="Title"
            okText="Save"
            onOk={handleOk}
            open={data.open}
            maskClosable={false}
            onCancel={handleCancel}
            confirmLoading={data.confirmLoading}
          >
            {data.errors &&
              ((data.errors as any).messages || (data.errors as any).error) && (
                <>
                  <Alert
                    showIcon
                    closable
                    type="error"
                    message={(data.errors as any).error}
                    afterClose={() =>
                      updateData((draft) => {
                        draft.errors = undefined;
                      })
                    }
                    description={
                      <ul>
                        {(data.errors as any).messages.map(
                          (value: string, index: number) => (
                            <li key={index}>{value}</li>
                          )
                        )}
                      </ul>
                    }
                  />
                  <br />
                </>
              )}

            <Form component="div" layout="vertical">
              <Form.Item label="Question">
                <Input
                  value={data.botQuestion.question}
                  onChange={(e) => {
                    updateData((draft) => {
                      draft.botQuestion.question = e.target.value;
                    });
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Checkbox
                  checked={data.botQuestion.isTrue}
                  onChange={(e) => {
                    updateData((draft) => {
                      draft.botQuestion.isTrue = e.target.checked;
                    });
                  }}
                >
                  Is true
                </Checkbox>
              </Form.Item>
            </Form>
          </Modal>
        </div>
        <Table
          rowKey="id"
          expandable={{
            expandedRowRender: (record: any) => (
              <ReactJson src={record.meta || undefined} />
            ),
          }}
          loading={loading}
          columns={columns}
          scroll={{ x: true }}
          pagination={{
            total: data.meta.totalItems,
            current: data.meta.currentPage,
            pageSize: data.meta.itemsPerPage,
            onChange: (page, pageSize) => {
              updateData((draft) => {
                draft.meta.currentPage = page;
                draft.meta.itemsPerPage = pageSize;
              });
            },
          }}
          dataSource={data?.items || []}
          onChange={onChange}
        />
      </Card>
    </>
  );
}
