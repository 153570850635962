import { AnyAction } from 'redux';
import { AffiliateAuth as AuthAffiliate } from '../../../types/affiliateAuth';
import { AffiliateAuth } from '../../enums/auth';

export const acUpsertAuthAffiliate = (
  data: Record<string, string>
): AnyAction => ({
  payload: data,
  type: AffiliateAuth.upsertAuthUser,
});

export const acSetAffiliateAccessToken = (token: string) => ({
  payload: token,
  type: AffiliateAuth.setAccessToken,
});

export const acAffiliateLogout = () => ({
  type: AffiliateAuth.logout,
});

export const acSetAffiliateMessage = (message?: AuthAffiliate.Message) => ({
  payload: message,
  type: AffiliateAuth.setMessage,
});
