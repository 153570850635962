export const ANIMATION_DELAY = 300;

export const s3PublicURL = process.env.REACT_APP_AWS_S3_URl;

export enum Genders {
  'male' = 'male',
  'female' = 'female',
}

export enum UserLanguages {
  ENGLISH = 'en',
  GERMAN = 'de',
}
