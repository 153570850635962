enum Auth {
  logout = 'auth/logout',
  setMessage = 'auth/setMessage',
  upsertAuthUser = 'auth/upsertAuthUser',
  setAccessToken = 'auth/setAccessToken',
}

export enum AffiliateAuth {
  logout = 'affiliate/logout',
  setMessage = 'affiliate/setMessage',
  upsertAuthUser = 'affiliate/upsertAuthUser',
  setAccessToken = 'affiliate/setAccessToken',
}

export default Auth;
