import { useSelector } from 'react-redux';
import { Button } from 'antd';
import React from 'react';
import { useImmer } from 'use-immer';
import dayjs from 'dayjs';
import { EditOutlined } from '@ant-design/icons';
import { sAuthUser } from '../../store/selectors/auth';
import api from '../../service';
import ApiRoutes from '../../constants/apiRoutes';
import { FiniteStates } from '../../constants/finiteStates';

interface ISetting {
  id?: number;
  configName: string;
  value: string;
}

const initialSetting: ISetting = {
  configName: '',
  value: '',
};

export function useSettings() {
  const authUser = useSelector(sAuthUser);

  const [data, updateData] = useImmer({
    items: [],
    state: FiniteStates.IDLE,
    open: false,
    errors: undefined,
    confirmLoading: false,
    setting: initialSetting,
  });

  const showModal = () => {
    updateData((draft) => {
      draft.open = true;
    });
  };

  const handleCancel = () => {
    updateData((draft) => {
      draft.open = false;
      draft.setting = initialSetting;
    });
  };

  async function reload() {
    try {
      updateData((draft) => {
        draft.state = FiniteStates.LOADING;
      });
      const res = await api.get(ApiRoutes.getSettings);

      updateData((draft) => {
        draft.items = res.data;
        draft.state = FiniteStates.SUCCESS;
      });
    } catch (e) {
      updateData((draft) => {
        draft.state = FiniteStates.FAILURE;
      });
    }
  }

  const handleOk = async () => {
    updateData((draft) => {
      draft.confirmLoading = true;
    });

    try {
      await api.post(ApiRoutes.updateSetting, data.setting);
      updateData((draft) => {
        draft.open = false;
        draft.errors = undefined;
        draft.setting = initialSetting;
      });

      reload().catch(console.error);
    } catch (e: any) {
      if (e.response?.data?.error || e.response?.data?.message) {
        updateData((draft) => {
          draft.errors = {
            error: e.response?.data.error,
            messages: e.response?.data.message,
          } as any;
        });
      } else {
        console.error(e);
      }
    }

    updateData((draft) => {
      draft.confirmLoading = false;
    });
  };

  function getSetting(setting: ISetting) {
    updateData((draft) => {
      draft.setting = {
        configName: setting.configName,
        value: setting.value,
        id: setting.id,
      } as any;

      draft.open = true;
    });
  }

  const [columns] = useImmer<any[]>([
    {
      title: 'Config Name',
      dataIndex: 'configName',
      key: 'configName',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (value: any) => {
        if (value) {
          return value.length > 100 ? `${value.substring(0, 100)}...` : value;
        }
        return '';
      },
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      ellipsis: { showTitle: true },
      key: 'updatedAt',
      render: (value: any) =>
        value ? dayjs(value).format('YYYY-MM-DD HH:MM') : '',
    },
    {
      title: 'Actions',
      ellipsis: { showTitle: true },
      key: 'actions',
      fixed: 'right',
      render: (value: any) => (
        <div>
          <Button
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => getSetting(value)}
          />
        </div>
      ),
    },
  ]);

  React.useEffect(() => {
    (async () => {
      await reload();
    })();
  }, []);

  return {
    data,
    columns,
    authUser,
    handleOk,
    showModal,
    updateData,
    handleCancel,
    loading: [FiniteStates.IDLE, FiniteStates.LOADING].includes(data.state),
  };
}
