import React from 'react';
import { Menu as AntMenu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import DashboardOutlined from '@ant-design/icons/UserSwitchOutlined';
import RobotOutlined from '@ant-design/icons/RobotOutlined';
import GatewayOutlined from '@ant-design/icons/GatewayOutlined';
import ContactsOutlined from '@ant-design/icons/ContactsOutlined';
import StopOutlined from '@ant-design/icons/StopOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import TeamOutlined from '@ant-design/icons/TeamOutlined';
import SketchOutlined from '@ant-design/icons/SketchOutlined';
import NotificationOutlined from '@ant-design/icons/NotificationOutlined';
import UserDeleteOutlined from '@ant-design/icons/UserDeleteOutlined';
import AppRoutes from '../../../../../../constants/appRoutes';

export default function Menu() {
  let { pathname } = useLocation();
  if (pathname.startsWith(AppRoutes.games)) {
    pathname = AppRoutes.games;
  } else if (pathname.startsWith(AppRoutes.users)) {
    pathname = AppRoutes.users;
  } else if (pathname.startsWith(AppRoutes.bots)) {
    pathname = AppRoutes.bots;
  }

  return (
    <AntMenu
      mode="inline"
      items={[
        {
          title: 'Users',
          key: AppRoutes.users,
          icon: <DashboardOutlined />,
          label: <Link to={AppRoutes.users}>Users</Link>,
        },
        {
          title: 'Bots',
          key: AppRoutes.bots,
          icon: <RobotOutlined />,
          label: <Link to={AppRoutes.bots}>Bots</Link>,
        },
        {
          title: 'Games',
          key: AppRoutes.games,
          icon: <GatewayOutlined />,
          label: <Link to={AppRoutes.games}>Games</Link>,
        },
        {
          title: 'Contacts',
          key: AppRoutes.contacts,
          icon: <ContactsOutlined />,
          label: <Link to={AppRoutes.contacts}>Contacts</Link>,
        },
        {
          title: 'Reports',
          key: AppRoutes.reports,
          icon: <StopOutlined />,
          label: <Link to={AppRoutes.reports}>Reports</Link>,
        },
        {
          title: 'Blockers',
          key: AppRoutes.blockers,
          icon: <UserDeleteOutlined />,
          label: <Link to={AppRoutes.blockers}>Blockers</Link>,
        },
        {
          title: 'Affiliates',
          key: AppRoutes.affiliates,
          icon: <TeamOutlined />,
          label: <Link to={AppRoutes.affiliates}>Affiliates</Link>,
        },
        {
          title: 'Notifications',
          key: AppRoutes.notifications,
          icon: <NotificationOutlined />,
          label: <Link to={AppRoutes.notifications}>Notifications</Link>,
        },
        {
          title: 'Ads',
          key: AppRoutes.ads,
          icon: <SketchOutlined />,
          label: <Link to={AppRoutes.ads}>Ads</Link>,
        },
        {
          title: 'Settings',
          key: AppRoutes.settings,
          icon: <SettingOutlined />,
          label: <Link to={AppRoutes.settings}>Settings</Link>,
        },
      ]}
      selectedKeys={[pathname]}
    />
  );
}
