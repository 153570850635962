import { AnyAction } from "redux";
import { Auth } from "../../../types/auth";
import AuthActionTypes from "../../enums/auth";

export const acUpsertAuthUser = (data: Record<string, string>): AnyAction => ({
  payload: data,
  type: AuthActionTypes.upsertAuthUser,
});

export const acSetAccessToken = (token: string) => ({
  payload: token,
  type: AuthActionTypes.setAccessToken,
});

export const acLogout = () => ({
  type: AuthActionTypes.logout,
});

export const acSetMessage = (message?: Auth.Message) => ({
  payload: message,
  type: AuthActionTypes.setMessage,
});
