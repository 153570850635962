import React from 'react';
import { Card, Spin, Table } from 'antd';
import { useGames } from './useGames';
import { GameStatuses } from '../../constants/gameStatuses';

export default function Games() {
  const { loading, data, columns, updateData, onChange, onExpand } = useGames();

  return (
    <Card>
      <Table
        rowKey="id"
        expandable={{
          expandedRowRender: (record: any) => {
            if (record.loading) {
              return <Spin />;
            }

            return (
              <table>
                <thead>
                  <tr>
                    <td>ID</td>
                    <td>Status</td>
                    <td>Created at</td>
                  </tr>
                </thead>
                <tbody>
                  {record.statuses.map((s: any) => (
                    <tr key={`${record}_${s.id}`}>
                      <td>{s.id}</td>
                      <td>{GameStatuses[s.status]}</td>
                      <td>{s.createdAt}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            );
          },
          onExpand,
        }}
        loading={loading}
        columns={columns}
        scroll={{ x: true }}
        pagination={{
          total: data.meta.totalItems,
          current: data.meta.currentPage,
          pageSize: data.meta.itemsPerPage,
          onChange: (page, pageSize) => {
            updateData((draft) => {
              draft.meta.currentPage = page;
              draft.meta.itemsPerPage = pageSize;
            });
          },
        }}
        dataSource={data?.items || []}
        onChange={onChange}
      />
    </Card>
  );
}
