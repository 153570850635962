import { useSelector } from 'react-redux';
import React from 'react';
import { useImmer } from 'use-immer';
import { Link, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { sAuthUser } from '../../store/selectors/auth';
import api from '../../service';
import ApiRoutes from '../../constants/apiRoutes';
import { FiniteStates } from '../../constants/finiteStates';
import { urlHelper } from '../../utils/urlHelper';
import AppRoutes from '../../constants/appRoutes';

export function useUserInfo() {
  const authUser = useSelector(sAuthUser);
  const { id } = useParams();

  const [data, updateData] = useImmer({
    user: {},
    gamesSender: [],
    transactions: [],
    gamesReceiver: [],
    state: FiniteStates.IDLE,
  });

  const [columns] = useImmer<any[]>([
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Coin',
      dataIndex: 'coin',
      key: 'coin',
    },
    {
      title: 'Game ID',
      dataIndex: 'gameId',
      key: 'gameId',
      render: (value: string) => (
        <Link to={urlHelper(AppRoutes.gameQuestions, { id: value })}>
          {value}
        </Link>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      ellipsis: { showTitle: true },
      key: 'createdAt',
      render: (value: any) =>
        value ? dayjs(value).format('YYYY-MM-DD HH:MM') : '',
    },
  ]);

  const [gamesSenderColumns] = useImmer<any[]>([
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (value: string) => (
        <Link to={urlHelper(AppRoutes.gameQuestions, { id: value })}>
          {value}
        </Link>
      ),
    },
    {
      title: 'Receiver',
      dataIndex: 'receiver',
      key: 'receiver',
      render: (value: any) => (
        <Link to={urlHelper(AppRoutes.userInfo, { id: value?.id })}>
          {value?.username}
        </Link>
      ),
    },
    {
      title: 'Finished At',
      dataIndex: 'finishedAt',
      ellipsis: { showTitle: true },
      key: 'finishedAt',
      render: (value: any) =>
        value ? dayjs(value).format('YYYY-MM-DD HH:MM') : '',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      ellipsis: { showTitle: true },
      key: 'createdAt',
      render: (value: any) =>
        value ? dayjs(value).format('YYYY-MM-DD HH:MM') : '',
    },
  ]);

  const [gamesSenderReceiver] = useImmer<any[]>([
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (value: string) => (
        <Link to={urlHelper(AppRoutes.gameQuestions, { id: value })}>
          {value}
        </Link>
      ),
    },
    {
      title: 'Sender',
      dataIndex: 'sender',
      key: 'sender',
      render: (value: any) => (
        <Link to={urlHelper(AppRoutes.userInfo, { id: value?.id })}>
          {value?.username}
        </Link>
      ),
    },
    {
      title: 'Finished At',
      dataIndex: 'finishedAt',
      ellipsis: { showTitle: true },
      key: 'finishedAt',
      render: (value: any) =>
        value ? dayjs(value).format('YYYY-MM-DD HH:MM') : '',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      ellipsis: { showTitle: true },
      key: 'createdAt',
      render: (value: any) =>
        value ? dayjs(value).format('YYYY-MM-DD HH:MM') : '',
    },
  ]);

  React.useEffect(() => {
    (async () => {
      try {
        updateData((draft) => {
          draft.state = FiniteStates.LOADING;
        });

        const info: any = await api.get(urlHelper(ApiRoutes.userInfo, { id }));

        updateData((draft) => {
          draft.user = info.data.user;
          draft.gamesSender = info.data.gamesSender;
          draft.transactions = info.data.transactions;
          draft.gamesReceiver = info.data.gamesReceiver;

          draft.state = FiniteStates.SUCCESS;
        });
      } catch (e) {
        updateData((draft) => {
          draft.state = FiniteStates.FAILURE;
        });
      }
    })();
  }, []);

  return {
    data,
    columns,
    authUser,
    updateData,
    gamesSenderColumns,
    gamesSenderReceiver,
    loading: [FiniteStates.IDLE, FiniteStates.LOADING].includes(data.state),
  };
}
