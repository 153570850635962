import { useSelector } from 'react-redux';
import { Button, Image, Popconfirm, Space, Tooltip } from 'antd';
import React from 'react';
import { useImmer } from 'use-immer';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload/interface';
import { sAuthUser } from '../../store/selectors/auth';
import api from '../../service';
import ApiRoutes from '../../constants/apiRoutes';
import { FiniteStates } from '../../constants/finiteStates';
import { s3PublicURL } from '../../constants';

interface InitAd {
  id?: number;
  text?: string;
  image?: RcFile | string;
  link?: string;
  from?: string;
  to?: string;
  countries?: any;
}

const initialAd: InitAd = {
  text: '',
  link: '',
  from: '',
  to: '',
  countries: [],
};

export function useAds() {
  const authUser = useSelector(sAuthUser);

  const [data, updateData] = useImmer({
    items: [],
    state: FiniteStates.IDLE,
    open: false,
    errors: undefined,
    confirmLoading: false,
    ad: initialAd,
  });

  const showModal = () => {
    updateData((draft) => {
      draft.open = true;
    });
  };

  const handleCancel = () => {
    updateData((draft) => {
      draft.open = false;
      draft.ad = initialAd;
    });
  };

  async function reload() {
    try {
      updateData((draft) => {
        draft.state = FiniteStates.LOADING;
      });

      const res = await api.get(ApiRoutes.adsGet);

      updateData((draft) => {
        draft.items = res.data;
        draft.state = FiniteStates.SUCCESS;
      });
    } catch (e) {
      updateData((draft) => {
        draft.state = FiniteStates.FAILURE;
      });
    }
  }

  const handleOk = async () => {
    updateData((draft) => {
      draft.confirmLoading = true;
    });

    try {
      if ((data.ad as InitAd).id) {
        await api.post(ApiRoutes.adsUpdate, {
          id: data.ad.id,
          text: data.ad.text,
          link: data.ad.link,
          from: data.ad.from,
          to: data.ad.to,
          countries: data.ad.countries || '',
        });
      } else {
        const formData = new FormData();
        formData.append('text', data.ad.text as string);
        formData.append('link', data.ad.link as string);
        formData.append('from', data.ad.from as string);
        formData.append('to', data.ad.to as string);
        formData.append('countries', data.ad.countries as any);
        if (data.ad.image) {
          // @ts-ignore
          formData.append('image', data.ad.image);
        }
        await api.post(ApiRoutes.adsCreate, formData);
      }

      updateData((draft) => {
        draft.open = false;
        draft.errors = undefined;
        draft.ad = initialAd;
      });

      reload().catch(console.error);
    } catch (e: any) {
      if (e.response?.data?.errors || e.response?.data?.message) {
        updateData((draft) => {
          draft.errors = {
            error: e.response?.data.message,
            messages: e.response?.data.errors,
          } as any;
        });
      } else {
        console.error(e);
      }
    }

    updateData((draft) => {
      draft.confirmLoading = false;
    });
  };

  function getAd(ad: any) {
    updateData((draft) => {
      draft.ad = {
        text: ad.text,
        link: ad.link,
        from: ad.from,
        to: ad.to,
        image: ad.image,
        id: ad.id,
        countries: ad.countries,
      } as any;

      draft.open = true;
    });
  }

  const [columns] = useImmer<any[]>([
    {
      title: 'Text',
      dataIndex: 'text',
      key: 'text',
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (value: any) =>
        value ? <Image src={s3PublicURL + value} width={32} /> : '',
    },
    {
      title: 'Country',
      dataIndex: 'countries',
      key: 'countries',
      ellipsis: { showTitle: true },
      render: (value: string[]) => (value ? value.toString() : ''),
    },
    {
      title: 'From Date',
      dataIndex: 'from',
      key: 'from',
    },
    {
      title: 'Until Date',
      dataIndex: 'to',
      key: 'to',
    },
    {
      title: 'Actions',
      key: 'actions',
      ellipsis: { showTitle: true },
      fixed: 'right',
      render: (row: any, _: any, index: number) => (
        <Space>
          <div>
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => getAd(row)}
            />
          </div>
          <Popconfirm
            okText="Yes"
            cancelText="No"
            placement="bottom"
            title="Delete Ad"
            onConfirm={async () => {
              try {
                await api.post(ApiRoutes.adsDelete, { id: row.id });
                updateData((draft) => {
                  draft.items = draft.items.filter((val, i) => i !== index);
                });
              } catch (e) {
                console.error(e);
              }
            }}
          >
            <Tooltip title="Delete Ad">
              <Button danger type="primary" size="small" shape="circle">
                <CloseOutlined />
              </Button>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]);

  React.useEffect(() => {
    (async () => {
      await reload();
    })();
  }, []);

  return {
    data,
    columns,
    authUser,
    handleOk,
    showModal,
    updateData,
    handleCancel,
    loading: [FiniteStates.IDLE, FiniteStates.LOADING].includes(data.state),
  };
}
