import React from 'react';
import { Avatar, Dropdown, Layout, Menu } from 'antd';
import LogoutOutlined from '@ant-design/icons/LogoutOutlined';
import { useDispatch } from 'react-redux';
import getPrefixCls from '../../../../utils/getPrefixCls';
import { acLogout } from '../../../../store/actions/auth';
import './index.css';

export default function Header() {
  const dispatch = useDispatch();

  const menu = (
    <Menu
      items={[
        {
          key: 'sign-out',
          label: 'Sign Out',
          title: 'Sign Out',
          icon: <LogoutOutlined />,
          onClick: () => dispatch(acLogout()),
        },
      ]}
    />
  );

  return (
    <Layout.Header className={getPrefixCls('layout-header')}>
      <div>Secret Game Admin</div>
      <div />
      <Dropdown arrow trigger={['click']} overlay={menu}>
        <Avatar className="cursor-pointer" size="small">
          ME
        </Avatar>
      </Dropdown>
    </Layout.Header>
  );
}
