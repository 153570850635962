import produce from 'immer';
import { Auth } from '../../../types/auth';
import AuthActionTypes from '../../enums/auth';

const InitialState: Auth.Reducer = {
  accessToken: localStorage.getItem('access_token'),
  isAuthenticated: !!localStorage.getItem('access_token'),
};

const auth = produce((draft, action) => {
  const { type, payload } = action;

  switch (type) {
    case AuthActionTypes.logout: {
      localStorage.removeItem('access_token');

      return {
        ...InitialState,
        accessToken: null,
        isAuthenticated: false,
      };
    }
    case AuthActionTypes.setMessage: {
      draft.message = payload;

      return undefined;
    }
    case AuthActionTypes.upsertAuthUser: {
      if (!draft.user) {
        draft.user = {};
      }

      Object.keys(payload).forEach((key) => {
        (draft.user as any)[key] = payload[key];
      });

      return undefined;
    }
    case AuthActionTypes.setAccessToken: {
      draft.accessToken = payload;
      draft.isAuthenticated = true;
      localStorage.setItem('access_token', payload);

      return undefined;
    }
    default:
      return undefined;
  }
}, InitialState);

export default auth;
