import { useSelector } from 'react-redux';
import {
  Avatar,
  Button,
  Image,
  Popconfirm,
  Space,
  TablePaginationConfig,
  Tag,
  Tooltip,
} from 'antd';
import {
  CheckCircleOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import React from 'react';
import { useImmer } from 'use-immer';
import { stringify } from 'query-string';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { RcFile } from 'antd/lib/upload/interface';
import { AvatarStatuses } from '../../constants/avatarStatuses';
import { sAuthUser } from '../../store/selectors/auth';
import api from '../../service';
import ApiRoutes from '../../constants/apiRoutes';
import { FiniteStates } from '../../constants/finiteStates';
import { urlHelper } from '../../utils/urlHelper';
import AppRoutes from '../../constants/appRoutes';
import { Genders, s3PublicURL, UserLanguages } from '../../constants';
import { trimStringByLength } from '../../utils/helpers';
import { SloganStatuses } from '../../constants/sloganStatuses';

interface InitUser {
  id?: number;
  age: number;
  slogan?: string;
  username: string;
  password?: string;
  gender: Genders;
  country?: string;
  language: UserLanguages;
  avatar?: RcFile | string;
}

const initialUser: InitUser = {
  age: 18,
  username: '',
  password: '',
  gender: Genders.male,
  language: UserLanguages.ENGLISH,
};

export function useUsers() {
  const authUser = useSelector(sAuthUser);

  const [data, updateData] = useImmer({
    items: [],
    meta: {
      totalPages: 0,
      totalItems: 0,
      itemCount: 10,
      currentPage: 1,
      itemsPerPage: 10,
    },
    sorter: {
      order: 'descend',
      field: 'created_at',
    },
    filters: {
      avatarStatus: [
        AvatarStatuses.WAITING,
        AvatarStatuses.APPROVED,
        AvatarStatuses.REJECTED,
        AvatarStatuses.NOT_UPLOADED,
      ],
      sloganStatus: [
        SloganStatuses.WAITING,
        SloganStatuses.APPROVED,
        SloganStatuses.REJECTED,
        SloganStatuses.EMPTY,
      ],
      gender: ['male', 'female'],
      username: '',
    },
    showOnlineUsers: false,
    state: FiniteStates.IDLE,
    open: false,
    errors: undefined,
    confirmLoading: false,
    user: initialUser,
  });

  const showModal = () => {
    updateData((draft) => {
      draft.open = true;
    });
  };

  const handleCancel = () => {
    updateData((draft) => {
      draft.open = false;
      draft.user = initialUser;
    });
  };

  async function reload() {
    try {
      updateData((draft) => {
        draft.state = FiniteStates.LOADING;
      });
      let res: any;

      let order: any = {};
      if ((data.sorter as any).order === 'descend') {
        order = {
          order: 'DESC',
          field: (data.sorter as any).field,
        };
      } else if ((data.sorter as any).order === 'ascend') {
        order = {
          order: 'ASC',
          field: (data.sorter as any).field,
        };
      }

      const params = {
        ...data.meta,
        gender: data.filters.gender ? data.filters.gender.join(',') : null,
        username: data.filters.username,
        avatarStatus: data.filters.avatarStatus
          ? data.filters.avatarStatus.join(',')
          : null,
        sloganStatus: data.filters.sloganStatus
          ? data.filters.sloganStatus.join(',')
          : null,
        ...order,
      };

      if (!data.showOnlineUsers) {
        res = await api.get(`${ApiRoutes.users}?${stringify(params)}`);
      } else {
        res = await api.get(`${ApiRoutes.onlineUsers}?${stringify(params)}`);
        res = {
          ...res,
          data: {
            ...res.data,
            items: res.data.items.map((t: any) => ({
              ...t.user,
            })),
          },
        };
      }

      updateData((draft) => {
        draft.meta = res.data.meta;
        draft.items = res.data.items;
        draft.state = FiniteStates.SUCCESS;
      });
    } catch (e) {
      updateData((draft) => {
        draft.state = FiniteStates.FAILURE;
      });
    }
  }

  const handleOk = async () => {
    updateData((draft) => {
      draft.confirmLoading = true;
    });

    try {
      if ((data.user as InitUser).id) {
        await api.post(ApiRoutes.usersUpdate, {
          id: data.user.id,
          age: data.user.age,
          gender: data.user.gender,
          slogan: data.user.slogan,
          country: data.user.country,
          language: data.user.language,
          password: data.user.password,
          username: data.user.username,
        });
        if (data.user?.avatar && (data.user.avatar as RcFile).uid) {
          const formData = new FormData();
          formData.append('id', (data.user.id as number).toString());
          // @ts-ignore
          formData.append('avatar', data.user.avatar);
          await api.post(ApiRoutes.uploadBotAvatar, formData);
        }
      } else {
        await api.post(ApiRoutes.register, data.user);
      }

      updateData((draft) => {
        draft.open = false;
        draft.errors = undefined;
        draft.user = initialUser;
      });

      reload().catch(console.error);
    } catch (e: any) {
      if (
        e.response?.data?.error ||
        e.response?.data?.errors ||
        e.response?.data?.message
      ) {
        updateData((draft) => {
          draft.errors = {
            error: e.response?.data?.errors
              ? e.response?.data.message
              : e.response?.data.error,
            messages: e.response?.data?.errors
              ? e.response.data.errors
              : e.response?.data.message,
          } as any;
        });
      } else {
        console.error(e);
      }
    }

    updateData((draft) => {
      draft.confirmLoading = false;
    });
  };

  function getUser(user: InitUser) {
    updateData((draft) => {
      draft.user = {
        age: user.age,
        gender: user.gender,
        slogan: user.slogan,
        country: user.country,
        username: user.username,
        language: user.language,
        id: user.id,
        avatar: user.avatar,
      } as any;

      draft.open = true;
    });
  }

  const [columns] = useImmer<any[]>([
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      render: (value: string) => (
        <Link to={urlHelper(AppRoutes.userInfo, { id: value })}>{value}</Link>
      ),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      sorter: true,
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      sorter: true,
    },
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (value: any, row: any) => {
        if (!value) {
          return (
            <Avatar src={s3PublicURL + value}>
              {row.username.charAt(0).toUpperCase()}
            </Avatar>
          );
        }
        return <Image src={s3PublicURL + value} width={32} />;
      },
    },
    {
      title: 'Avatar status',
      dataIndex: 'avatarStatus',
      key: 'avatarStatus',
      ellipsis: { showTitle: true },
      render: (value: any) => {
        switch (value) {
          case AvatarStatuses.NOT_UPLOADED: {
            return (
              <Tag icon={<MinusCircleOutlined />} color="default">
                not uploaded
              </Tag>
            );
          }
          case AvatarStatuses.REJECTED: {
            return (
              <Tag icon={<CloseCircleOutlined />} color="error">
                rejected
              </Tag>
            );
          }
          case AvatarStatuses.APPROVED: {
            return (
              <Tag icon={<CheckCircleOutlined />} color="success">
                approved
              </Tag>
            );
          }
          case AvatarStatuses.WAITING: {
            return (
              <Tag icon={<ClockCircleOutlined />} color="default">
                waiting
              </Tag>
            );
          }
          default: {
            return (
              <Tag icon={<ExclamationCircleOutlined />} color="warning">
                warning
              </Tag>
            );
          }
        }
      },
      filters: [
        {
          text: 'Approved',
          value: AvatarStatuses.APPROVED,
        },
        {
          text: 'Rejected',
          value: AvatarStatuses.REJECTED,
        },
        {
          text: 'Not Uploaded',
          value: AvatarStatuses.NOT_UPLOADED,
        },
        {
          text: 'Waiting',
          value: AvatarStatuses.WAITING,
        },
      ],
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      ellipsis: { showTitle: true },
    },
    {
      title: 'Language',
      dataIndex: 'language',
      key: 'language',
      ellipsis: { showTitle: true },
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      filters: [
        {
          text: 'Female',
          value: 'female',
        },
        {
          text: 'Male',
          value: 'male',
        },
      ],
    },
    {
      title: 'Slogan',
      dataIndex: 'slogan',
      key: 'slogan',
      ellipsis: { showTitle: true },
      render: (value: any) => trimStringByLength(value),
    },
    {
      title: 'Slogan status',
      dataIndex: 'sloganStatus',
      key: 'sloganStatus',
      ellipsis: { showTitle: true },
      render: (value: any) => {
        switch (value) {
          case SloganStatuses.EMPTY: {
            return (
              <Tag icon={<MinusCircleOutlined />} color="default">
                empty
              </Tag>
            );
          }
          case SloganStatuses.REJECTED: {
            return (
              <Tag icon={<CloseCircleOutlined />} color="error">
                rejected
              </Tag>
            );
          }
          case SloganStatuses.APPROVED: {
            return (
              <Tag icon={<CheckCircleOutlined />} color="success">
                approved
              </Tag>
            );
          }
          case SloganStatuses.WAITING: {
            return (
              <Tag icon={<ClockCircleOutlined />} color="default">
                waiting
              </Tag>
            );
          }
          default: {
            return (
              <Tag icon={<ExclamationCircleOutlined />} color="warning">
                warning
              </Tag>
            );
          }
        }
      },
      filters: [
        {
          text: 'Approved',
          value: SloganStatuses.APPROVED,
        },
        {
          text: 'Rejected',
          value: SloganStatuses.REJECTED,
        },
        {
          text: 'Empty',
          value: SloganStatuses.EMPTY,
        },
        {
          text: 'Waiting',
          value: SloganStatuses.WAITING,
        },
      ],
    },
    {
      title: 'Coins',
      dataIndex: 'coins',
      key: 'coins',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      ellipsis: { showTitle: true },
      key: 'createdAt',
      sorter: true,
      render: (value: any) =>
        value ? dayjs(value).format('YYYY-MM-DD HH:MM') : '',
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      ellipsis: { showTitle: true },
      key: 'updatedAt',
      sorter: true,
      render: (value: any) =>
        value ? dayjs(value).format('YYYY-MM-DD HH:MM') : '',
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (row: any, _: any, index: number) => {
        return (
          <Space>
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => getUser(row)}
            />
            {row.avatarStatus === AvatarStatuses.WAITING && (
              <>
                Avatar:
                <Popconfirm
                  okText="Yes"
                  cancelText="No"
                  placement="bottom"
                  title="Reject Avatar?"
                  onConfirm={async () => {
                    try {
                      await api.post(
                        urlHelper(ApiRoutes.rejectAvatar, { id: row.id })
                      );
                      updateData((draft) => {
                        (draft.items[index] as any).avatarStatus =
                          AvatarStatuses.REJECTED;
                      });
                    } catch (e) {
                      console.error(e);
                    }
                  }}
                >
                  <Tooltip title="Reject Avatar">
                    <Button danger type="primary" size="small" shape="circle">
                      <CloseOutlined />
                    </Button>
                  </Tooltip>
                </Popconfirm>
                <Popconfirm
                  okText="Yes"
                  cancelText="No"
                  placement="bottom"
                  title="Approve Avatar?"
                  onConfirm={async () => {
                    try {
                      await api.post(
                        urlHelper(ApiRoutes.approveAvatar, { id: row.id })
                      );
                      updateData((draft) => {
                        (draft.items[index] as any).avatarStatus =
                          AvatarStatuses.APPROVED;
                      });
                    } catch (e) {
                      console.error(e);
                    }
                  }}
                >
                  <Tooltip title="Approve Avatar">
                    <Button type="primary" size="small" shape="circle">
                      <CheckOutlined />
                    </Button>
                  </Tooltip>
                </Popconfirm>
              </>
            )}
            {row.sloganStatus === SloganStatuses.WAITING && (
              <>
                Slogan:
                <Popconfirm
                  okText="Yes"
                  cancelText="No"
                  placement="bottom"
                  title="Delete Slogan?"
                  onConfirm={async () => {
                    try {
                      await api.post(
                        urlHelper(ApiRoutes.updateSlogan, { id: row.id }),
                        {
                          sloganStatus: SloganStatuses.REJECTED,
                        }
                      );
                      updateData((draft) => {
                        (draft.items[index] as any).sloganStatus =
                          SloganStatuses.REJECTED;
                      });
                    } catch (e) {
                      console.error(e);
                    }
                  }}
                >
                  <Tooltip title="Reject Slogan">
                    <Button danger type="primary" size="small" shape="circle">
                      <CloseOutlined />
                    </Button>
                  </Tooltip>
                </Popconfirm>
                <Popconfirm
                  okText="Yes"
                  cancelText="No"
                  placement="bottom"
                  title="Approve Slogan?"
                  onConfirm={async () => {
                    try {
                      await api.post(
                        urlHelper(ApiRoutes.updateSlogan, { id: row.id }),
                        {
                          sloganStatus: SloganStatuses.APPROVED,
                        }
                      );
                      updateData((draft) => {
                        (draft.items[index] as any).sloganStatus =
                          SloganStatuses.APPROVED;
                      });
                    } catch (e) {
                      console.error(e);
                    }
                  }}
                >
                  <Tooltip title="Approve Slogan">
                    <Button type="primary" size="small" shape="circle">
                      <CheckOutlined />
                    </Button>
                  </Tooltip>
                </Popconfirm>
              </>
            )}
          </Space>
        );

        return <span />;
      },
    },
  ]);

  React.useEffect(() => {
    (async () => {
      await reload();
    })();
  }, [
    data.sorter,
    data.filters,
    data.meta.currentPage,
    data.meta.itemsPerPage,
    data.showOnlineUsers,
  ]);

  function onChange(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) {
    if (sorter) {
      updateData((draft) => {
        draft.sorter = {
          order: (sorter as any).order,
          field: (sorter as any).field,
        };
      });
    }
    updateData((draft) => {
      draft.filters.gender = (filters?.gender as any) || [];
      draft.filters.username = data.filters.username || '';
      draft.filters.avatarStatus = (filters?.avatarStatus as any) || [];
      draft.filters.sloganStatus = (filters?.sloganStatus as any) || [];
    });
  }

  return {
    data,
    columns,
    authUser,
    onChange,
    updateData,
    handleOk,
    showModal,
    handleCancel,
    reload,
    loading: [FiniteStates.IDLE, FiniteStates.LOADING].includes(data.state),
  };
}
