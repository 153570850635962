import React from 'react';
import { Card, Table } from 'antd';
import { useReports } from './useReports';

export default function Reports() {
  const { loading, data, columns, updateData, onChange } = useReports();

  return (
    <Card>
      <Table
        rowKey="id"
        loading={loading}
        columns={columns}
        scroll={{ x: true }}
        pagination={{
          total: data.meta.totalItems,
          current: data.meta.currentPage,
          pageSize: data.meta.itemsPerPage,
          onChange: (page, pageSize) => {
            updateData((draft) => {
              draft.meta.currentPage = page;
              draft.meta.itemsPerPage = pageSize;
            });
          },
        }}
        dataSource={data?.items || []}
        onChange={onChange}
      />
    </Card>
  );
}
