import React from 'react';
import { Alert, Card, Form, Input, Modal, Table } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useSettings } from './useSettings';

export default function Settings() {
  const { data, columns, loading, handleOk, updateData, handleCancel } =
    useSettings();

  return (
    <Card>
      <div style={{ marginBottom: 16 }}>
        {data.open && (
          <Modal
            title="Title"
            okText="Save"
            onOk={handleOk}
            open={data.open}
            maskClosable={false}
            onCancel={handleCancel}
            confirmLoading={data.confirmLoading}
          >
            {data.errors &&
              ((data.errors as any).messages || (data.errors as any).error) && (
                <>
                  <Alert
                    showIcon
                    closable
                    type="error"
                    message={(data.errors as any).error}
                    afterClose={() =>
                      updateData((draft) => {
                        draft.errors = undefined;
                      })
                    }
                    description={
                      <ul>
                        {(data.errors as any).messages.map(
                          (value: string, index: number) => (
                            <li key={index}>{value}</li>
                          )
                        )}
                      </ul>
                    }
                  />
                  <br />
                </>
              )}

            <Form component="div" layout="vertical">
              <h1>{data.setting.configName}</h1>
              <Form.Item label="Value">
                {data.setting.configName === 'win_coin' ? (
                  <Input
                    type="number"
                    value={data.setting.value}
                    onChange={(e) => {
                      updateData((draft) => {
                        draft.setting.value = e.target.value;
                      });
                    }}
                  />
                ) : (
                  <TextArea
                    value={data.setting.value}
                    onChange={(e) => {
                      updateData((draft) => {
                        draft.setting.value = e.target.value;
                      });
                    }}
                  />
                )}
              </Form.Item>
            </Form>
          </Modal>
        )}
      </div>
      <Table
        rowKey="id"
        loading={loading}
        columns={columns}
        scroll={{ x: true }}
        dataSource={data?.items || []}
      />
    </Card>
  );
}
