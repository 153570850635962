import { useSelector } from 'react-redux';
import {
  Button,
  Popconfirm,
  Space,
  TablePaginationConfig,
  Tooltip,
} from 'antd';
import React from 'react';
import dayjs from 'dayjs';
import { useImmer } from 'use-immer';
import { stringify } from 'query-string';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { CloseOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { sAuthUser } from '../../store/selectors/auth';
import api from '../../service';
import ApiRoutes from '../../constants/apiRoutes';
import { FiniteStates } from '../../constants/finiteStates';
import { urlHelper } from '../../utils/urlHelper';
import AppRoutes from '../../constants/appRoutes';

export function useBlockers() {
  const authUser = useSelector(sAuthUser);

  const [data, updateData] = useImmer({
    items: [],
    meta: {
      totalPages: 0,
      totalItems: 0,
      itemCount: 10,
      currentPage: 1,
      itemsPerPage: 10,
    },
    sorter: {
      order: 'descend',
      field: 'id',
    },
    state: FiniteStates.IDLE,
  });

  const [columns] = useImmer<any[]>([
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: 'From',
      dataIndex: 'user',
      ellipsis: { showTitle: true },
      key: 'from',
      render: (value: any) => (
        <Link to={urlHelper(AppRoutes.userInfo, { id: value?.id })}>
          {value?.username}
        </Link>
      ),
    },
    {
      title: 'To',
      dataIndex: 'blocker',
      ellipsis: { showTitle: true },
      key: 'to',
      render: (value: any) => (
        <Link to={urlHelper(AppRoutes.userInfo, { id: value?.id })}>
          {value?.username}
        </Link>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      ellipsis: { showTitle: true },
      key: 'createdAt',
      sorter: true,
      render: (value: any) =>
        value ? dayjs(value).format('YYYY-MM-DD HH:MM') : '',
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: (row: any, _: any, index: number) => (
        <Space>
          <Popconfirm
            okText="Yes"
            cancelText="No"
            placement="bottom"
            title="Delete"
            onConfirm={async () => {
              try {
                await api.post(ApiRoutes.deleteBlockers, { id: row.id });
                updateData((draft) => {
                  draft.items = draft.items.filter((val, i) => i !== index);
                });
              } catch (e) {
                console.error(e);
              }
            }}
          >
            <Tooltip title="Delete">
              <Button danger type="primary" size="small" shape="circle">
                <CloseOutlined />
              </Button>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]);

  React.useEffect(() => {
    (async () => {
      try {
        updateData((draft) => {
          draft.state = FiniteStates.LOADING;
        });
        let res: any;

        let order: any = {};
        if ((data.sorter as any).order === 'descend') {
          order = {
            order: 'DESC',
            field: (data.sorter as any).field,
          };
        } else if ((data.sorter as any).order === 'ascend') {
          order = {
            order: 'ASC',
            field: (data.sorter as any).field,
          };
        }
        const params = {
          ...data.meta,
          ...order,
        };

        res = await api.get(`${ApiRoutes.blockers}?${stringify(params)}`);
        res = {
          ...res,
          data: {
            ...res.data,
            items: res.data.items,
          },
        };

        updateData((draft) => {
          draft.meta = res.data.meta;
          draft.items = res.data.items;
          draft.state = FiniteStates.SUCCESS;
        });
      } catch (e) {
        updateData((draft) => {
          draft.state = FiniteStates.FAILURE;
        });
      }
    })();
  }, [data.sorter, data.meta.currentPage, data.meta.itemsPerPage]);

  function onChange(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) {
    if (sorter) {
      updateData((draft) => {
        draft.sorter = {
          order: (sorter as any).order,
          field: (sorter as any).field,
        };
      });
    }
  }

  return {
    data,
    columns,
    authUser,
    onChange,
    updateData,
    loading: [FiniteStates.IDLE, FiniteStates.LOADING].includes(data.state),
  };
}
