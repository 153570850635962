import React, { useState } from 'react';
import { Button, Card, Input, Select, Spin, Typography, Col, Row } from 'antd';
import { useNotifications } from './useNotifications';
import countries from '../../constants/contries.json';
import { Genders, UserLanguages } from '../../constants';

const { Title } = Typography;
const { TextArea } = Input;

export default function Users() {
  const [ageFrom, setAgeFrom] = useState('');
  const [ageTo, setAgeTo] = useState('');
  const [offlineDays, setOfflineDays] = useState('');

  const { loading, data, updateData, onSubmit } = useNotifications();
  return (
    <>
      <Title level={2} style={{ textAlign: 'center' }}>
        Filters
      </Title>
      <Card>
        <Row>
          <Col span={6}>
            Country:{' '}
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label.toLowerCase() ?? '').includes(input)
              }
              options={[
                {
                  value: '',
                  label: 'All',
                },
                ...countries.map((val) => ({
                  value: val.code,
                  label: val.name,
                })),
              ]}
              onChange={(value) => {
                updateData((draft) => {
                  draft.filters.country = value;
                });
              }}
              value={data.filters.country}
            />
          </Col>
          <Col span={6} offset={3}>
            Language:{' '}
            <Select
              style={{ width: '100%' }}
              options={[
                {
                  value: '',
                  label: 'All',
                },
                ...Object.keys(UserLanguages).map((val) => ({
                  // @ts-ignore
                  value: UserLanguages[val],
                  label: val,
                })),
              ]}
              onChange={(value) => {
                updateData((draft) => {
                  draft.filters.language = value;
                });
              }}
              value={data.filters.language}
            />
          </Col>
          <Col span={6} offset={3}>
            Gender:{' '}
            <Select
              style={{ width: '100%' }}
              options={[
                {
                  value: '',
                  label: 'All',
                },
                ...Object.keys(Genders).map((val) => ({
                  value: val,
                  label: val,
                })),
              ]}
              onChange={(value) => {
                updateData((draft) => {
                  draft.filters.gender = value;
                });
              }}
              value={data.filters.gender}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col span={8}>
            <div>Age From-To:</div>
            <Input
              style={{ width: '35%' }}
              type="number"
              placeholder="min age"
              onChange={(elem) => {
                setAgeFrom(elem.target.value);
              }}
              value={ageFrom}
            />
            <Input
              style={{ width: '35%' }}
              type="number"
              placeholder="max age"
              onChange={(elem) => {
                setAgeTo(elem.target.value);
              }}
              value={ageTo}
            />
            <Button
              type="primary"
              style={{ width: '30%' }}
              onClick={() => {
                updateData((draft) => {
                  draft.filters.ageFrom = ageFrom;
                  draft.filters.ageTo = ageTo;
                });
              }}
            >
              Apply
            </Button>
          </Col>
          <Col span={8} offset={8}>
            <div>Offline Days:</div>
            <Input
              type="number"
              style={{ width: '70%' }}
              placeholder="Put offline days"
              onChange={(elem) => {
                setOfflineDays(elem.target.value);
              }}
              value={offlineDays}
            />
            <Button
              type="primary"
              style={{ width: '30%' }}
              onClick={() => {
                updateData((draft) => {
                  draft.filters.offlineDays = offlineDays;
                });
              }}
            >
              Apply
            </Button>
          </Col>
        </Row>
      </Card>
      <br />
      {loading ? (
        <Spin />
      ) : (
        <Title level={3} style={{ textAlign: 'center' }}>
          Count: {` ${data.count}`}
        </Title>
      )}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Card style={{ width: '60%' }}>
          <Title level={4} style={{ textAlign: 'center' }}>
            Title
          </Title>
          <Input
            placeholder="title of notification"
            onChange={(elem) => {
              updateData((draft) => {
                draft.notTitle = elem.target.value;
              });
            }}
            value={data.notTitle}
          />
          <Title level={4} style={{ textAlign: 'center', marginTop: 10 }}>
            Text
          </Title>
          <TextArea
            placeholder="text"
            rows={2}
            onChange={(elem) => {
              updateData((draft) => {
                draft.notText = elem.target.value;
              });
            }}
            value={data.notText}
            maxLength={100}
          />
          <div
            style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}
          >
            <Button type="primary" loading={loading} onClick={onSubmit}>
              Send Notifications
            </Button>
          </div>
        </Card>
      </div>
    </>
  );
}
