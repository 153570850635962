const ApiRoutes = {
  me: '/api/admin/me',
  login: '/api/auth/login',
  register: '/api/auth/register',
  onlineUser: '/api/users/online',
  users: '/api/admin/users',
  usersUpdate: '/api/admin/users/update',
  userInfo: '/api/admin/users/:id/info',
  games: '/api/admin/games',
  gameStatuses: '/api/admin/games/:id/statuses',
  gameQuestions: '/api/admin/games/:id/questions',
  onlineUsers: '/api/admin/online-users',
  approveAvatar: '/api/admin/users/:id/approve-avatar',
  rejectAvatar: '/api/admin/users/:id/reject-avatar',
  deleteAvatar: '/api/admin/users/:id/delete-avatar',
  updateSlogan: '/api/admin/users/:id/update-slogan',
  deleteUser: '/api/admin/users/delete/:id',
  bots: '/api/bots/get',
  botQuestions: '/api/bots/question/get',
  createBotQuestion: '/api/bots/question/create',
  updateBotQuestion: '/api/bots/question/update',
  deleteBotQuestion: '/api/bots/question/delete',
  createBot: '/api/bots/create',
  updateBot: '/api/bots/update',
  uploadBotAvatar: '/api/bots/upload-avatar',
  getBot: '/api/bots/create',
  contacts: '/api/contacts/get',
  updateContact: '/api/contacts/update',
  deleteContact: '/api/contacts/delete',
  reports: '/api/reports/get',
  updateReport: '/api/reports/update',
  deleteReport: '/api/reports/delete',
  blockers: '/api/blockers/get',
  deleteBlockers: '/api/blockers/delete',
  getSettings: '/api/settings/get',
  updateSetting: '/api/settings/update',
  createAffiliate: '/api/affiliate/create',
  getAffiliates: '/api/affiliate/get',
  updateAffiliate: '/api/affiliate/update',
  getAffiliateInfo: '/api/affiliate/:id/info',
  deleteAffiliate: '/api/affiliate/delete',
  affiliateLogin: '/api/affiliate/auth',
  meAffiliate: '/api/affiliate/me',
  getAffiliateStats: '/api/affiliate/stats/get',
  setAffiliateBesecret: '/api/affiliate/set-besecret',
  usersNotificationList: '/api/admin/users/notification/list',
  usersNotificationBulk: '/api/admin/users/notification/bulk',
  adsGet: '/api/ads/get',
  adsCreate: '/api/ads/create',
  adsUpdate: '/api/ads/update',
  adsDelete: '/api/ads/delete',
};

export default ApiRoutes;
