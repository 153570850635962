import { useSelector } from 'react-redux';
import React from 'react';
import { useImmer } from 'use-immer';
import { stringify } from 'query-string';
import { sAuthUser } from '../../store/selectors/auth';
import api from '../../service';
import ApiRoutes from '../../constants/apiRoutes';
import { FiniteStates } from '../../constants/finiteStates';
import { openNotificationWithIcon } from '../../utils/notification';

export function useNotifications() {
  const authUser = useSelector(sAuthUser);

  const [data, updateData] = useImmer({
    count: 0,
    filters: {
      country: '',
      ageFrom: '',
      ageTo: '',
      language: '',
      offlineDays: '',
      gender: '',
    },
    notTitle: '',
    notText: '',
    state: FiniteStates.IDLE,
  });

  React.useEffect(() => {
    (async () => {
      try {
        updateData((draft) => {
          draft.state = FiniteStates.LOADING;
        });

        const res = await api.get(
          `${ApiRoutes.usersNotificationList}?${stringify(data.filters)}`
        );

        updateData((draft) => {
          draft.count = res?.data || 0;
          draft.state = FiniteStates.SUCCESS;
        });
      } catch (e) {
        updateData((draft) => {
          draft.state = FiniteStates.FAILURE;
        });
      }
    })();
  }, [data.filters]);

  async function onSubmit() {
    try {
      if (!data.notTitle || !data.notText) {
        openNotificationWithIcon('warning', 'Please fill all fields', '');
        return;
      }
      updateData((draft) => {
        draft.state = FiniteStates.LOADING;
      });

      const res = await api.post(ApiRoutes.usersNotificationBulk, {
        ...data.filters,
        title: data.notTitle,
        text: data.notText,
      });

      if (res?.status === 200) {
        openNotificationWithIcon('success', 'Notification Sent', '');
      }
      updateData((draft) => {
        draft.state = FiniteStates.SUCCESS;
      });
    } catch (e) {
      openNotificationWithIcon('error', 'Something Went Wrong', '');
      updateData((draft) => {
        draft.state = FiniteStates.FAILURE;
      });
    }
  }

  return {
    data,
    authUser,
    updateData,
    onSubmit,
    loading: [FiniteStates.IDLE, FiniteStates.LOADING].includes(data.state),
  };
}
