import React, { useState } from 'react';
import {
  Card,
  Space,
  DatePicker,
  Table,
  Select,
  Input,
  Button,
  Typography,
  Tooltip,
} from 'antd';
import moment from 'moment';
import { useAffiliateHome } from './useAffiliateHome';
import countries from '../../constants/contries.json';

export const baseURL = process.env.REACT_APP_BASE_URL as string;

const { Title } = Typography;

enum DayKeys {
  FROM = 'dayFrom',
  TO = 'dayTo',
}

export default function AffiliateHome() {
  const {
    loading,
    data,
    columns,
    updateData,
    onChange,
    authAffiliate,
    sendBesecret,
  } = useAffiliateHome();

  const [subId, setSubId] = useState('');
  const [besecret, setBesecret] = useState(
    () => authAffiliate.affiliate?.besecret
  );
  const [subIdLink, setSubIdLink] = useState('?subId=YOUR_SUBID');

  const onChangeDate = (date: string, key: DayKeys) => {
    updateData((draft) => {
      draft.filters[key] = date;
    });
  };
  const appDownloadLink = React.useMemo(() => {
    setBesecret(authAffiliate.affiliate?.besecret);
    return `${baseURL}api/mobile-app/download/${authAffiliate.affiliate?.username}`;
  }, [authAffiliate]);

  return (
    <>
      <Title level={2} style={{ textAlign: 'center' }}>
        Links
      </Title>
      <Card>
        <div>
          <span style={{ width: 120, display: 'inline-block' }}>
            App Download:{' '}
          </span>
          <Tooltip
            mouseEnterDelay={0}
            placement="top"
            title="Text copied"
            trigger={['click']}
          >
            <Input
              readOnly
              style={{ width: 700 }}
              value={appDownloadLink}
              onClick={() => navigator.clipboard.writeText(appDownloadLink)}
            />
          </Tooltip>
        </div>
        <div style={{ marginTop: 15 }}>
          <span style={{ width: 120, display: 'inline-block' }}>
            {' '}
            Links with subId:{' '}
          </span>
          <Tooltip
            mouseEnterDelay={0}
            placement="top"
            title="Text copied"
            trigger={['click']}
          >
            <Input
              style={{ width: 700 }}
              value={subIdLink}
              onChange={(e) => setSubIdLink(e.target.value)}
              onClick={() => navigator.clipboard.writeText(subIdLink)}
            />
          </Tooltip>
        </div>
      </Card>
      <Card>
        <Space align="center">
          BeSecret username:{' '}
          <Input
            placeholder="your username"
            onChange={(elem) => {
              setBesecret(elem.target.value);
            }}
            value={besecret}
          />
          <Button
            type="primary"
            onClick={() => {
              sendBesecret(besecret);
            }}
          >
            Save Username
          </Button>
        </Space>
      </Card>
      <Title level={2} style={{ textAlign: 'center', marginTop: 10 }}>
        Data
      </Title>
      <Card>
        <Space align="center" style={{ marginRight: 16 }}>
          Date From:{' '}
          <DatePicker
            format="YYYY-MM-DD"
            onChange={(date, str) => onChangeDate(str, DayKeys.FROM)}
            value={moment(data.filters.dayFrom)}
          />
        </Space>
        <Space align="center" style={{ marginRight: 16 }}>
          Date To:{' '}
          <DatePicker
            format="YYYY-MM-DD"
            onChange={(date, str) => onChangeDate(str, DayKeys.TO)}
            value={data.filters.dayTo ? moment(data.filters.dayTo) : null}
          />
        </Space>
        <Space align="center" style={{ marginRight: 16 }}>
          Countries:{' '}
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? '').includes(input)
            }
            options={[
              {
                value: '',
                label: 'All',
              },
              ...countries.map((val) => ({
                value: val.code,
                label: val.name,
              })),
            ]}
            onChange={(value) => {
              updateData((draft) => {
                draft.filters.country = value;
              });
            }}
            value={data.filters.country}
          />
        </Space>
        <Space align="center">
          SubId:{' '}
          <Input
            placeholder="Search by subId"
            onChange={(elem) => {
              setSubId(elem.target.value);
            }}
            value={subId}
          />
          <Button
            type="primary"
            onClick={() => {
              updateData((draft) => {
                draft.filters.subId = subId;
              });
            }}
          >
            Search SubId
          </Button>
        </Space>
      </Card>
      <br />
      <Space align="center" style={{ fontSize: 16, paddingLeft: 5 }}>
        Clicked:{` ${data.clicked}`}
      </Space>
      <Card>
        <Table
          rowKey="id"
          loading={loading}
          columns={columns}
          scroll={{ x: true }}
          pagination={{
            total: data.meta.totalItems,
            current: data.meta.currentPage,
            pageSize: data.meta.itemsPerPage,
            onChange: (page, pageSize) => {
              updateData((draft) => {
                draft.meta.currentPage = page;
                draft.meta.itemsPerPage = pageSize;
              });
            },
          }}
          dataSource={data?.items || []}
          onChange={onChange}
        />
      </Card>
    </>
  );
}
