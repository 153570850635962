import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { baseURL } from './store/api';

const apiAffiliate = (function api() {
  let instance: AxiosInstance;

  function createInstance() {
    const axiosInstance = axios.create({
      baseURL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const accessToken = localStorage.getItem('affiliate_token');

    if (accessToken) {
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }

    return axiosInstance;
  }

  return {
    getInstance: () => {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
    get(url: string, config?: AxiosRequestConfig<any>) {
      const i = this.getInstance();
      return i.get(url, config);
    },
    post(url: string, data?: any, config?: AxiosRequestConfig<any>) {
      const i = this.getInstance();
      return i.post(url, data, config);
    },
    delete(url: string, config?: AxiosRequestConfig<any>) {
      const i = this.getInstance();
      return i.delete(url, config);
    },
  };
})();

export default apiAffiliate;
