import React from "react";
import { Layout } from "antd";
import getPrefixCls from "../../../../utils/getPrefixCls";
import "./index.css";

export default function Content({ children }: { children: React.ReactNode }) {
  return (
    <Layout.Content className={getPrefixCls("layout-content")}>
      {children}
    </Layout.Content>
  );
}
