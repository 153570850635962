import { useSelector } from 'react-redux';
import { TablePaginationConfig } from 'antd';
import React from 'react';
import { useImmer } from 'use-immer';
import { stringify } from 'query-string';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { sAuthUser } from '../../store/selectors/auth';
import api from '../../service';
import ApiRoutes from '../../constants/apiRoutes';
import { FiniteStates } from '../../constants/finiteStates';
import { urlHelper } from '../../utils/urlHelper';
import AppRoutes from '../../constants/appRoutes';

export function useGames() {
  const authUser = useSelector(sAuthUser);

  const [data, updateData] = useImmer({
    items: [],
    meta: {
      totalPages: 0,
      totalItems: 0,
      itemCount: 10,
      currentPage: 1,
      itemsPerPage: 10,
    },
    sorter: {
      order: 'descend',
      field: 'created_at',
    },
    state: FiniteStates.IDLE,
  });

  const [columns] = useImmer<any[]>([
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (value: any, row: any) => {
        if (row.finishedAt) {
          return (
            <Link to={urlHelper(AppRoutes.gameQuestions, { id: value })}>
              {value}
            </Link>
          );
        }
        return value;
      },
    },
    {
      title: 'Sender',
      dataIndex: 'sender',
      ellipsis: { showTitle: true },
      key: 'sender',
      render: (value: any) => (
        <Link to={urlHelper(AppRoutes.userInfo, { id: value?.id })}>
          {value?.username}
        </Link>
      ),
    },
    {
      title: 'Receiver',
      dataIndex: 'receiver',
      ellipsis: { showTitle: true },
      key: 'receiver',
      render: (value: any) => (
        <Link to={urlHelper(AppRoutes.userInfo, { id: value?.id })}>
          {value?.username}
        </Link>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      ellipsis: { showTitle: true },
      key: 'createdAt',
      sorter: true,
      render: (value: any) =>
        value ? dayjs(value).format('YYYY-MM-DD HH:MM') : '',
    },
    {
      title: 'Finished At',
      dataIndex: 'finishedAt',
      ellipsis: { showTitle: true },
      key: 'finishedAt',
      sorter: true,
      render: (value: any) =>
        value ? dayjs(value).format('YYYY-MM-DD HH:MM') : '',
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      ellipsis: { showTitle: true },
      key: 'updatedAt',
      sorter: true,
      render: (value: any) =>
        value ? dayjs(value).format('YYYY-MM-DD HH:MM') : '',
    },
  ]);

  React.useEffect(() => {
    (async () => {
      try {
        updateData((draft) => {
          draft.state = FiniteStates.LOADING;
        });

        let order: any = {};
        if ((data.sorter as any).order === 'descend') {
          order = {
            order: 'DESC',
            field: (data.sorter as any).field,
          };
        } else if ((data.sorter as any).order === 'ascend') {
          order = {
            order: 'ASC',
            field: (data.sorter as any).field,
          };
        }

        const params = {
          ...data.meta,
          ...order,
        };

        const res = await api.get(`${ApiRoutes.games}?${stringify(params)}`);

        updateData((draft) => {
          draft.meta = res.data.meta;
          draft.items = res.data.items;
          draft.state = FiniteStates.SUCCESS;
        });
      } catch (e) {
        updateData((draft) => {
          draft.state = FiniteStates.FAILURE;
        });
      }
    })();
  }, [data.sorter, data.meta.currentPage, data.meta.itemsPerPage]);

  function onChange(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) {
    if (sorter) {
      updateData((draft) => {
        draft.sorter = {
          order: (sorter as any).order,
          field: (sorter as any).field,
        };
      });
    }
  }

  async function onExpand(expanded: boolean, record: any) {
    if (expanded) {
      const index = data.items.findIndex((d: any) => d.id === record.id);

      updateData((draft) => {
        (draft.items[index] as any).loading = true;
      });

      try {
        const res = await api.get(
          urlHelper(ApiRoutes.gameStatuses, {
            id: record.id,
          })
        );

        updateData((draft) => {
          (draft.items[index] as any).loading = undefined;
          (draft.items[index] as any).statuses = res.data;
        });
      } catch (e) {
        updateData((draft) => {
          (draft.items[index] as any).loading = undefined;
        });
      }
    }
  }

  return {
    data,
    columns,
    authUser,
    onChange,
    onExpand,
    updateData,
    loading: [FiniteStates.IDLE, FiniteStates.LOADING].includes(data.state),
  };
}
