import { useSelector } from 'react-redux';
import React from 'react';
import { useImmer } from 'use-immer';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { sAuthUser } from '../../store/selectors/auth';
import api from '../../service';
import ApiRoutes from '../../constants/apiRoutes';
import { FiniteStates } from '../../constants/finiteStates';
import { urlHelper } from '../../utils/urlHelper';

export function useGameQuestions() {
  const { id } = useParams();
  const authUser = useSelector(sAuthUser);

  const [data, updateData] = useImmer({
    game: {},
    items: [],
    state: FiniteStates.IDLE,
  });

  const [columns] = useImmer<any[]>([
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'From',
      dataIndex: 'from',
      ellipsis: { showTitle: true },
      key: 'from',
      render: (value: any) => value.username,
    },
    {
      title: 'To',
      dataIndex: 'to',
      ellipsis: { showTitle: true },
      key: 'to',
      render: (value: any) => value.username,
    },
    {
      title: 'Question',
      dataIndex: 'question',
      ellipsis: { showTitle: true },
      key: 'question',
    },
    {
      title: 'Answer',
      dataIndex: 'answer',
      ellipsis: { showTitle: true },
      key: 'answer',
      render: (value: any) => `${value}`,
    },
    {
      title: 'Is true',
      dataIndex: 'isTrue',
      ellipsis: { showTitle: true },
      key: 'isTrue',
      render: (value: any) => `${value}`,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      ellipsis: { showTitle: true },
      key: 'createdAt',
      render: (value: any) =>
        value ? dayjs(value).format('YYYY-MM-DD HH:MM') : '',
    },
  ]);

  React.useEffect(() => {
    (async () => {
      try {
        updateData((draft) => {
          draft.state = FiniteStates.LOADING;
        });

        const res = await api.get(urlHelper(ApiRoutes.gameQuestions, { id }));

        updateData((draft) => {
          draft.game = res.data.game;
          draft.items = res.data.items;
          draft.state = FiniteStates.SUCCESS;
        });
      } catch (e) {
        updateData((draft) => {
          draft.state = FiniteStates.FAILURE;
        });
      }
    })();
  }, []);

  return {
    data,
    columns,
    authUser,
    updateData,
    loading: [FiniteStates.IDLE, FiniteStates.LOADING].includes(data.state),
  };
}
