import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { sIsAuthenticated } from '../../../../store/selectors/auth';
import AppRoutes from '../../../../constants/appRoutes';

export function RequireAuth({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const isAuthenticated = useSelector(sIsAuthenticated);

  if (isAuthenticated) {
    return children;
  }

  return <Navigate to={AppRoutes.login} state={{ from: location }} replace />;
}
