import { useSelector } from 'react-redux';
import { Avatar, Button, Image, TablePaginationConfig } from 'antd';
import React from 'react';
import { useImmer } from 'use-immer';
import { stringify } from 'query-string';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload/interface';
import { sAuthUser } from '../../store/selectors/auth';
import api from '../../service';
import ApiRoutes from '../../constants/apiRoutes';
import { FiniteStates } from '../../constants/finiteStates';
import { Genders, s3PublicURL, UserLanguages } from '../../constants';
import { urlHelper } from '../../utils/urlHelper';
import AppRoutes from '../../constants/appRoutes';
import countries from '../../constants/contries.json';
import { trimStringByLength } from '../../utils/helpers';

interface InitBot {
  id?: number;
  age: string;
  slogan: string;
  username: string;
  gender: Genders;
  country: string;
  language: string;
  avatar?: RcFile | string;
}

const initialBot: InitBot = {
  age: '18',
  slogan: '',
  username: '',
  language: UserLanguages.ENGLISH,
  gender: Genders.male,
  country: countries[0].code,
};

export function useBots() {
  const authUser = useSelector(sAuthUser);

  const [data, updateData] = useImmer({
    items: [],
    meta: {
      totalPages: 0,
      totalItems: 0,
      itemCount: 10,
      currentPage: 1,
      itemsPerPage: 10,
    },
    sorter: {
      order: 'descend',
      field: 'created_at',
    },
    filters: {
      gender: ['male', 'female'],
      username: '',
    },
    state: FiniteStates.IDLE,
    open: false,
    errors: undefined,
    confirmLoading: false,
    bot: initialBot,
  });

  const showModal = () => {
    updateData((draft) => {
      draft.open = true;
    });
  };

  const handleCancel = () => {
    updateData((draft) => {
      draft.open = false;
      draft.bot = initialBot;
    });
  };

  async function reload() {
    try {
      updateData((draft) => {
        draft.state = FiniteStates.LOADING;
      });
      let res: any;

      let order: any = {};
      if ((data.sorter as any).order === 'descend') {
        order = {
          order: 'DESC',
          field: (data.sorter as any).field,
        };
      } else if ((data.sorter as any).order === 'ascend') {
        order = {
          order: 'ASC',
          field: (data.sorter as any).field,
        };
      }
      const params = {
        ...data.meta,
        gender: data.filters.gender ? data.filters.gender.join(',') : null,
        username: data.filters.username,
        ...order,
      };

      res = await api.get(`${ApiRoutes.bots}?${stringify(params)}`);
      res = {
        ...res,
        data: {
          ...res.data,
          items: res.data.items,
        },
      };

      updateData((draft) => {
        draft.meta = res.data.meta;
        draft.items = res.data.items;
        draft.state = FiniteStates.SUCCESS;
      });
    } catch (e) {
      updateData((draft) => {
        draft.state = FiniteStates.FAILURE;
      });
    }
  }

  const handleOk = async () => {
    updateData((draft) => {
      draft.confirmLoading = true;
    });

    try {
      if ((data.bot as InitBot).id) {
        await api.post(ApiRoutes.updateBot, {
          ...data.bot,
          age: Number(data.bot.age),
        });
        if (data.bot?.avatar && (data.bot.avatar as RcFile).uid) {
          const formData = new FormData();
          formData.append('id', (data.bot.id as number).toString());
          // @ts-ignore
          formData.append('avatar', data.bot.avatar);
          await api.post(ApiRoutes.uploadBotAvatar, formData);
        }
      } else {
        await api.post(ApiRoutes.createBot, {
          ...data.bot,
          age: Number(data.bot.age),
        });
      }

      updateData((draft) => {
        draft.open = false;
        draft.errors = undefined;
        draft.bot = initialBot;
      });

      reload().catch(console.error);
    } catch (e: any) {
      if (e.response?.data?.error || e.response?.data?.message) {
        updateData((draft) => {
          draft.errors = {
            error: e.response?.data?.errors
              ? e.response?.data.message
              : e.response?.data.error,
            messages: e.response?.data?.errors
              ? e.response.data.errors
              : e.response?.data.message,
          } as any;
        });
      } else {
        console.error(e);
      }
    }

    updateData((draft) => {
      draft.confirmLoading = false;
    });
  };

  function getBot(bot: any) {
    updateData((draft) => {
      draft.bot = {
        age: bot.age,
        gender: bot.gender,
        slogan: bot.slogan,
        country: bot.country,
        language: bot.language,
        username: bot.username,
        avatar: bot.avatar,
        id: bot.id,
      } as any;

      draft.open = true;
    });
  }

  const [columns] = useImmer<any[]>([
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      render: (value: any) => (
        <Link to={urlHelper(AppRoutes.botQuestions, { id: value })}>
          {value}
        </Link>
      ),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      sorter: true,
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      sorter: true,
    },
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (value: any, row: any) => {
        if (!value) {
          return (
            <Avatar src={s3PublicURL + value}>
              {row.username.charAt(0).toUpperCase()}
            </Avatar>
          );
        }
        return <Image src={s3PublicURL + value} width={32} />;
      },
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      ellipsis: { showTitle: true },
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      filters: [
        {
          text: 'Female',
          value: 'female',
        },
        {
          text: 'Male',
          value: 'male',
        },
      ],
    },
    {
      title: 'Slogan',
      dataIndex: 'slogan',
      key: 'slogan',
      ellipsis: { showTitle: true },
      render: (value: any) => trimStringByLength(value),
    },
    {
      title: 'Language',
      dataIndex: 'language',
      key: 'language',
      ellipsis: { showTitle: true },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      ellipsis: { showTitle: true },
      key: 'createdAt',
      sorter: true,
      render: (value: any) =>
        value ? dayjs(value).format('YYYY-MM-DD HH:MM') : '',
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      ellipsis: { showTitle: true },
      key: 'updatedAt',
      sorter: true,
      render: (value: any) =>
        value ? dayjs(value).format('YYYY-MM-DD HH:MM') : '',
    },
    {
      title: 'Actions',
      ellipsis: { showTitle: true },
      key: 'actions',
      fixed: 'right',
      render: (value: any) => (
        <div>
          <Button
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => getBot(value)}
          />
        </div>
      ),
    },
  ]);

  React.useEffect(() => {
    (async () => {
      try {
        updateData((draft) => {
          draft.state = FiniteStates.LOADING;
        });
        let res: any;

        let order: any = {};
        if ((data.sorter as any).order === 'descend') {
          order = {
            order: 'DESC',
            field: (data.sorter as any).field,
          };
        } else if ((data.sorter as any).order === 'ascend') {
          order = {
            order: 'ASC',
            field: (data.sorter as any).field,
          };
        }
        const params = {
          ...data.meta,
          gender: data.filters.gender ? data.filters.gender.join(',') : null,
          username: data.filters.username,
          ...order,
        };

        res = await api.get(`${ApiRoutes.bots}?${stringify(params)}`);
        res = {
          ...res,
          data: {
            ...res.data,
            items: res.data.items,
          },
        };

        updateData((draft) => {
          draft.meta = res.data.meta;
          draft.items = res.data.items;
          draft.state = FiniteStates.SUCCESS;
        });
      } catch (e) {
        updateData((draft) => {
          draft.state = FiniteStates.FAILURE;
        });
      }
    })();
  }, [
    data.sorter,
    data.filters,
    data.meta.currentPage,
    data.meta.itemsPerPage,
  ]);

  function onChange(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) {
    if (sorter) {
      updateData((draft) => {
        draft.sorter = {
          order: (sorter as any).order,
          field: (sorter as any).field,
        };
      });
    }

    updateData((draft) => {
      draft.filters.gender = (filters?.gender as any) || [];
      draft.filters.username = data.filters.username || '';
    });
  }

  return {
    data,
    columns,
    authUser,
    onChange,
    handleOk,
    reload,
    showModal,
    updateData,
    handleCancel,
    loading: [FiniteStates.IDLE, FiniteStates.LOADING].includes(data.state),
  };
}
