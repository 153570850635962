import { combineReducers } from 'redux';
import auth from './auth';
import affiliateAuth from './affiliateAuth';
import application from './application';

const createRootReducer = () =>
  combineReducers({
    auth,
    affiliateAuth,
    application,
  });

export default createRootReducer;
