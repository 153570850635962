import produce from 'immer';
import { AffiliateAuth as AuthAffiliate } from '../../../types/affiliateAuth';
import { AffiliateAuth } from '../../enums/auth';

const InitialState: AuthAffiliate.Reducer = {
  accessToken: localStorage.getItem('affiliate_token'),
  isAuthenticated: !!localStorage.getItem('affiliate_token'),
};

const affiliateAuth = produce((draft, action) => {
  const { type, payload } = action;

  switch (type) {
    case AffiliateAuth.logout: {
      localStorage.removeItem('affiliate_token');
      return {
        ...InitialState,
        accessToken: null,
        isAuthenticated: false,
      };
    }
    case AffiliateAuth.setMessage: {
      draft.message = payload;

      return undefined;
    }
    case AffiliateAuth.upsertAuthUser: {
      if (!draft.affiliate) {
        draft.affiliate = {};
      }

      Object.keys(payload).forEach((key) => {
        (draft.affiliate as any)[key] = payload[key];
      });

      return undefined;
    }
    case AffiliateAuth.setAccessToken: {
      draft.accessToken = payload;
      draft.isAuthenticated = true;
      localStorage.setItem('affiliate_token', payload);

      return undefined;
    }
    default:
      return undefined;
  }
}, InitialState);

export default affiliateAuth;
