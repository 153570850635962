import React, { useState } from 'react';
import {
  Breadcrumb,
  Card,
  Space,
  DatePicker,
  Table,
  Select,
  Input,
  Button,
} from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useAffiliateInfo } from './useAffiliateInfo';
import AppRoutes from '../../constants/appRoutes';
import countries from '../../constants/contries.json';

enum DayKeys {
  FROM = 'dayFrom',
  TO = 'dayTo',
}

export default function AffiliateInfo() {
  const { loading, data, columns, updateData, onChange } = useAffiliateInfo();

  const [subId, setSubId] = useState('');

  const onChangeDate = (date: string, key: DayKeys) => {
    updateData((draft) => {
      draft.filters[key] = date;
    });
  };

  return (
    <>
      <Card>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={AppRoutes.affiliates}>Affiliates</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{data.username}</Breadcrumb.Item>
        </Breadcrumb>
      </Card>
      <br />
      <Card>
        <Space align="center" style={{ marginRight: 16 }}>
          Date From:{' '}
          <DatePicker
            format="YYYY-MM-DD"
            onChange={(date, str) => onChangeDate(str, DayKeys.FROM)}
            value={moment(data.filters.dayFrom)}
          />
        </Space>
        <Space align="center" style={{ marginRight: 16 }}>
          Date To:{' '}
          <DatePicker
            format="YYYY-MM-DD"
            onChange={(date, str) => onChangeDate(str, DayKeys.TO)}
            value={data.filters.dayTo ? moment(data.filters.dayTo) : null}
          />
        </Space>
        <Space align="center" style={{ marginRight: 16 }}>
          Countries:{' '}
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? '').includes(input)
            }
            options={[
              {
                value: '',
                label: 'All',
              },
              ...countries.map((val) => ({
                value: val.code,
                label: val.name,
              })),
            ]}
            onChange={(value) => {
              updateData((draft) => {
                draft.filters.country = value;
              });
            }}
            value={data.filters.country}
          />
        </Space>
      </Card>
      <Card>
        <Space align="center">
          SubId:{' '}
          <Input
            placeholder="Search by subId"
            onChange={(elem) => {
              setSubId(elem.target.value);
            }}
            value={subId}
          />
          <Button
            type="primary"
            onClick={() => {
              updateData((draft) => {
                draft.filters.subId = subId;
              });
            }}
          >
            Search SubId
          </Button>
        </Space>
      </Card>
      <br />
      <Space align="center" style={{ fontSize: 16, paddingLeft: 5 }}>
        Clicked:{` ${data.clicked}`}
      </Space>
      <Card>
        <Table
          rowKey="id"
          loading={loading}
          columns={columns}
          scroll={{ x: true }}
          pagination={{
            total: data.meta.totalItems,
            current: data.meta.currentPage,
            pageSize: data.meta.itemsPerPage,
            onChange: (page, pageSize) => {
              updateData((draft) => {
                draft.meta.currentPage = page;
                draft.meta.itemsPerPage = pageSize;
              });
            },
          }}
          dataSource={data?.items || []}
          onChange={onChange}
        />
      </Card>
    </>
  );
}
