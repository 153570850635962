import { useSelector } from 'react-redux';
import { Button, Popconfirm, TablePaginationConfig } from 'antd';
import React from 'react';
import { useImmer } from 'use-immer';
import { stringify } from 'query-string';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { FiniteStates } from '../../constants/finiteStates';
import ApiRoutes from '../../constants/apiRoutes';
import api from '../../service';
import { sAuthUser } from '../../store/selectors/auth';

const initialBotQuestion = {
  bot: '0',
  isTrue: true,
  question: '',
};

export function useBotQuestions() {
  const authUser = useSelector(sAuthUser);
  const { id } = useParams<{ id: string }>();

  const [data, updateData] = useImmer({
    items: [],
    meta: {
      totalPages: 0,
      totalItems: 0,
      itemCount: 10,
      currentPage: 1,
      itemsPerPage: 10,
    },
    sorter: {
      order: 'descend',
      field: 'created_at',
    },
    state: FiniteStates.IDLE,
    open: false,
    errors: undefined,
    confirmLoading: false,
    botQuestion: initialBotQuestion,
  });

  const showModal = () => {
    updateData((draft) => {
      draft.open = true;
    });
  };

  const handleCancel = () => {
    updateData((draft) => {
      draft.open = false;
      draft.botQuestion = initialBotQuestion;
    });
  };

  async function reload() {
    try {
      updateData((draft) => {
        draft.state = FiniteStates.LOADING;
      });
      let res: any;

      let order: any = {};
      if ((data.sorter as any).order === 'descend') {
        order = {
          order: 'DESC',
          field: (data.sorter as any).field,
        };
      } else if ((data.sorter as any).order === 'ascend') {
        order = {
          order: 'ASC',
          field: (data.sorter as any).field,
        };
      }
      const params = {
        ...data.meta,
        ...order,
        bot: id,
      };

      res = await api.get(`${ApiRoutes.botQuestions}?${stringify(params)}`);
      res = {
        ...res,
        data: {
          ...res.data,
          items: res.data.items,
        },
      };

      updateData((draft) => {
        draft.meta = {
          ...draft.meta,
          ...res.data.meta,
          currentPage: Number(res.data.meta.currentPage),
          itemsPerPage: Number(res.data.meta.itemsPerPage),
        };
        draft.items = res.data.items;
        draft.state = FiniteStates.SUCCESS;
      });
    } catch (e) {
      updateData((draft) => {
        draft.state = FiniteStates.FAILURE;
      });
    }
  }

  const handleOk = async () => {
    updateData((draft) => {
      draft.confirmLoading = true;
    });

    try {
      if ((data.botQuestion as any).id) {
        await api.post(ApiRoutes.updateBotQuestion, {
          ...data.botQuestion,
          bot: Number(id),
        });
      } else {
        await api.post(ApiRoutes.createBotQuestion, {
          ...data.botQuestion,
          bot: Number(id),
        });
      }

      updateData((draft) => {
        draft.open = false;
        draft.errors = undefined;
        draft.botQuestion = initialBotQuestion;
      });

      reload().catch(console.error);
    } catch (e: any) {
      if (e.response?.data?.error || e.response?.data?.message) {
        updateData((draft) => {
          draft.errors = {
            error: e.response?.data.error,
            messages: e.response?.data.message,
          } as any;
        });
      } else {
        console.error(e);
      }
    }

    updateData((draft) => {
      draft.confirmLoading = false;
    });
  };

  const confirm = async (e: any) => {
    updateData((draft) => {
      draft.confirmLoading = true;
    });

    try {
      await api.delete(ApiRoutes.deleteBotQuestion, {
        data: { id: Number(e.id) },
      });

      reload().catch(console.error);
    } catch (er: any) {
      console.log(er.response);
    }

    updateData((draft) => {
      draft.confirmLoading = false;
    });
  };

  const cancel = (e: any) => {
    console.log(e.id);
  };

  const [columns] = useImmer<any[]>([
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: 'Is true',
      dataIndex: 'isTrue',
      key: 'isTrue',
      sorter: true,
      ellipsis: { showTitle: true },
      render: (value: any) => `${value}`,
    },
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
      sorter: true,
      ellipsis: { showTitle: true },
    },
    {
      title: 'Play Counter',
      dataIndex: 'playCounter',
      key: 'playCounter',
      sorter: true,
      ellipsis: { showTitle: true },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      ellipsis: { showTitle: true },
      key: 'createdAt',
      sorter: true,
      render: (value: any) =>
        value ? dayjs(value).format('YYYY-MM-DD HH:MM') : '',
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      ellipsis: { showTitle: true },
      key: 'updatedAt',
      sorter: true,
      render: (value: any) =>
        value ? dayjs(value).format('YYYY-MM-DD HH:MM') : '',
    },
    {
      title: 'Actions',
      ellipsis: { showTitle: true },
      key: 'actions',
      fixed: 'right',
      render: (value: any) => (
        <div>
          <Popconfirm
            okText="Yes"
            cancelText="No"
            onCancel={() => cancel(value)}
            onConfirm={() => confirm(value)}
            title="Are you sure to delete this question?"
          >
            <Button danger shape="circle" icon={<DeleteOutlined />} />
          </Popconfirm>
        </div>
      ),
    },
  ]);

  React.useEffect(() => {
    (async () => {
      try {
        updateData((draft) => {
          draft.state = FiniteStates.LOADING;
        });
        let res: any;

        let order: any = {};
        if ((data.sorter as any).order === 'descend') {
          order = {
            order: 'DESC',
            field: (data.sorter as any).field,
          };
        } else if ((data.sorter as any).order === 'ascend') {
          order = {
            order: 'ASC',
            field: (data.sorter as any).field,
          };
        }
        const params = {
          ...data.meta,
          ...order,
          bot: id,
        };

        res = await api.get(`${ApiRoutes.botQuestions}?${stringify(params)}`);
        res = {
          ...res,
          data: {
            ...res.data,
            items: res.data.items,
          },
        };

        updateData((draft) => {
          draft.meta = {
            ...draft.meta,
            ...res.data.meta,
            currentPage: Number(res.data.meta.currentPage),
            itemsPerPage: Number(res.data.meta.itemsPerPage),
          };
          draft.items = res.data.items;
          draft.state = FiniteStates.SUCCESS;
        });
      } catch (e) {
        updateData((draft) => {
          draft.state = FiniteStates.FAILURE;
        });
      }
    })();
  }, [data.sorter, data.meta.currentPage, data.meta.itemsPerPage]);

  function onChange(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) {
    if (sorter) {
      updateData((draft) => {
        draft.sorter = {
          order: (sorter as any).order,
          field: (sorter as any).field,
        };
      });
    }
  }

  return {
    data,
    columns,
    authUser,
    onChange,
    handleOk,
    showModal,
    updateData,
    handleCancel,
    loading: [FiniteStates.IDLE, FiniteStates.LOADING].includes(data.state),
  };
}
