import React from "react";
import { Layout } from "antd";
import Logo from "./components/Logo";
import Menu from "./components/Menu";
import getPrefixCls from "../../../../utils/getPrefixCls";
import "./index.css";

export default function Sider() {
  return (
    <Layout.Sider theme="light" className={getPrefixCls("layout-sider")}>
      <Logo />
      <Menu />
    </Layout.Sider>
  );
}
