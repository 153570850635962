import React, { useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
  Upload,
} from 'antd';
import ReactJson from 'react-json-view';
import { CloseOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useUsers } from './useUsers';
import { Genders, s3PublicURL, UserLanguages } from '../../constants';
import countries from '../../constants/contries.json';
import api from '../../service';
import { urlHelper } from '../../utils/urlHelper';
import ApiRoutes from '../../constants/apiRoutes';

const { Option } = Select;

export default function Users() {
  const {
    loading,
    data,
    columns,
    updateData,
    onChange,
    handleOk,
    showModal,
    handleCancel,
    reload,
  } = useUsers();

  const [username, setUsername] = useState('');

  return (
    <Card>
      <div style={{ marginBottom: 16 }}>
        <Button onClick={showModal} icon={<PlusOutlined />}>
          Create User
        </Button>
        {data.open && (
          <Modal
            title="Title"
            okText="Save"
            onOk={handleOk}
            open={data.open}
            maskClosable={false}
            onCancel={handleCancel}
            confirmLoading={data.confirmLoading}
          >
            {data.errors &&
              ((data.errors as any).messages || (data.errors as any).error) && (
                <>
                  <Alert
                    showIcon
                    closable
                    type="error"
                    message={(data.errors as any).error}
                    afterClose={() =>
                      updateData((draft) => {
                        draft.errors = undefined;
                      })
                    }
                    description={
                      <ul>
                        {(data.errors as any).messages.map(
                          (value: string, index: number) => (
                            <li key={index}>{value}</li>
                          )
                        )}
                      </ul>
                    }
                  />
                  <br />
                </>
              )}

            <Form component="div" layout="vertical">
              <Form.Item label="Username">
                <Input
                  value={data.user.username}
                  onChange={(e) => {
                    updateData((draft) => {
                      draft.user.username = e.target.value;
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label="Password">
                <Input
                  value={data.user.password}
                  onChange={(e) => {
                    updateData((draft) => {
                      draft.user.password = e.target.value;
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label="Gender">
                <Select
                  onChange={(value) => {
                    updateData((draft) => {
                      draft.user.gender = value;
                    });
                  }}
                  value={data.user.gender || ''}
                >
                  {Object.values(Genders).map((value: string) => (
                    <Option key={value}>{value}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Age">
                <Input
                  value={data.user.age}
                  type="number"
                  onChange={(e) => {
                    updateData((draft) => {
                      draft.user.age = +e.target.value;
                    });
                  }}
                />
              </Form.Item>

              <Form.Item label="Language">
                <Select
                  onChange={(value) => {
                    updateData((draft) => {
                      draft.user.language = value;
                    });
                  }}
                  value={data.user.language || ''}
                >
                  {Object.keys(UserLanguages).map((val) => (
                    // @ts-ignore
                    <Option key={UserLanguages[val]}>{val}</Option>
                  ))}
                </Select>
              </Form.Item>

              {!!data?.user?.id && (
                <>
                  <Form.Item label="Country">
                    <Select
                      onChange={(value) => {
                        updateData((draft) => {
                          draft.user.country = value;
                        });
                      }}
                      value={data.user.country || ''}
                    >
                      {countries.map((value) => (
                        <Option key={value.code}>{value.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Slogan">
                    <Input
                      value={data.user.slogan}
                      onChange={(e) => {
                        updateData((draft) => {
                          draft.user.slogan = e.target.value;
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item label="Avatar">
                    <Upload
                      name="avatar"
                      defaultFileList={[]}
                      multiple={false}
                      maxCount={1}
                      listType="picture-card"
                      accept=".png,.jpeg,.jpg"
                      beforeUpload={() => false}
                      onChange={(file) => {
                        updateData((draft) => {
                          draft.user.avatar = file.fileList.length
                            ? file.fileList[0].originFileObj
                            : '';
                        });
                      }}
                    >
                      <Button icon={<UploadOutlined />} />
                    </Upload>
                    {data.user.avatar && typeof data.user.avatar === 'string' && (
                      <>
                        <Image
                          src={s3PublicURL + data.user.avatar}
                          width={60}
                        />
                        <Popconfirm
                          okText="Yes"
                          cancelText="No"
                          placement="bottom"
                          title="Are you sure to delete avatar?"
                          onConfirm={async () => {
                            try {
                              await api.delete(
                                urlHelper(ApiRoutes.deleteAvatar, {
                                  id: data.user.id,
                                })
                              );
                              handleCancel();
                              await reload();
                            } catch (e) {
                              console.error(e);
                            }
                          }}
                        >
                          <Tooltip title="Delete Avatar">
                            <Button
                              style={{
                                marginLeft: 5,
                              }}
                              danger
                              type="primary"
                              size="small"
                              shape="circle"
                            >
                              <CloseOutlined />
                            </Button>
                          </Tooltip>
                        </Popconfirm>
                      </>
                    )}
                  </Form.Item>
                </>
              )}
            </Form>
            {!!data?.user?.id && (
              <Popconfirm
                okText="Yes"
                cancelText="No"
                placement="bottom"
                title="Are you sure to delete?"
                onConfirm={async () => {
                  try {
                    await api.delete(
                      urlHelper(ApiRoutes.deleteUser, { id: data.user.id })
                    );
                    handleCancel();
                    await reload();
                  } catch (e) {
                    console.error(e);
                  }
                }}
              >
                <Tooltip title="Delete User">
                  <Button danger type="primary" size="middle" shape="default">
                    <CloseOutlined />
                  </Button>
                </Tooltip>
              </Popconfirm>
            )}
          </Modal>
        )}
      </div>
      <Space align="center" style={{ marginBottom: 16 }}>
        Show only online users:{' '}
        <Switch
          checked={data.showOnlineUsers}
          onChange={(checked) => {
            updateData((draft) => {
              draft.showOnlineUsers = checked;
            });
          }}
        />
      </Space>
      <Card>
        <Space align="center">
          Username:{' '}
          <Input
            placeholder="Search by username"
            onChange={(elem) => {
              setUsername(elem.target.value);
            }}
            value={username}
          />
          <Button
            type="primary"
            onClick={() => {
              updateData((draft) => {
                draft.meta.currentPage = 1;
                draft.filters.username = username;
              });
            }}
          >
            Search User
          </Button>
        </Space>
      </Card>
      <Table
        rowKey="id"
        expandable={{
          expandedRowRender: (record: any) => (
            <ReactJson src={record.meta || undefined} />
          ),
        }}
        loading={loading}
        columns={columns}
        scroll={{ x: true }}
        pagination={{
          total: data.meta.totalItems,
          current: data.meta.currentPage,
          pageSize: data.meta.itemsPerPage,
          onChange: (page, pageSize) => {
            updateData((draft) => {
              draft.meta.currentPage = page;
              draft.meta.itemsPerPage = pageSize;
            });
          },
        }}
        dataSource={data?.items || []}
        onChange={onChange}
      />
    </Card>
  );
}
