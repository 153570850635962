import { createSelector } from 'reselect';
import getState from './index';

export const sAffiliateAuth = createSelector(
  getState,
  (state) => state.affiliateAuth
);

export const sIsAffiliateAuthenticated = createSelector(
  sAffiliateAuth,
  (auth) => auth.isAuthenticated
);

export const sAffiliateMessage = createSelector(
  sAffiliateAuth,
  (auth) => auth.message
);
export const sAuthAffiliate = createSelector(
  sAffiliateAuth,
  (auth) => auth.affiliate
);
export const sAffiliateAccessToken = createSelector(
  sAffiliateAuth,
  (auth) => auth.accessToken
);
