import {acSetAccessToken, acSetMessage} from "./index";
import {Globals} from "../../../types/globals";
import ApiRoutes from "../../../constants/apiRoutes";
import MessageTypes from "../../../constants/messageTypes";
import {Forms} from "../../../types/forms";

const acFetchToken = (formValues: Forms.Login): Globals.ThunkAction => async (dispatch, getState, {api}) => {
  acSetMessage(undefined);

  const {
    hasError,
    body: {data, message},
  } = await api.post(ApiRoutes.login, formValues);

  if (hasError) {
    dispatch(
      acSetMessage({
        message,
        type: MessageTypes.ERROR,
      })
    );
  } else {
    dispatch(acSetAccessToken(data.access_token))
  }
};

export default acFetchToken;
