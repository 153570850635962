import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import AffiliateLayout from './layouts/Affiliate';
import AppRoutes from './constants/appRoutes';
import Login from './pages/Login';
import Users from './pages/Users';
import Bots from './pages/Bots';
import Games from './pages/Games';
import GameQuestions from './pages/GameQuestions';
import UserInfo from './pages/UserInfo';
import Contacts from './pages/Contacts';
import Reports from './pages/Reports';
import BotQuestions from './pages/BotQuestions';
import Settings from './pages/Settings';
import Affiliates from './pages/Affiliates';
import AffiliateInfo from './pages/AffiliateInfo';
import AffiliateLogin from './pages/AffiliateLogin';
import Affiliate from './pages/Affiliate';
import Notifications from './pages/Notifications';
import Ads from './pages/Ads';
import Blockers from './pages/Blockers';

function App() {
  return (
    <Routes>
      <Route path={AppRoutes.base} element={<AuthLayout />}>
        <Route index element={<Users />} />
        <Route path={AppRoutes.users} element={<Users />} />
        <Route path={AppRoutes.userInfo} element={<UserInfo />} />

        <Route path={AppRoutes.bots}>
          <Route index element={<Bots />} />
          <Route path={AppRoutes.botQuestions} element={<BotQuestions />} />
        </Route>

        <Route path={AppRoutes.contacts} element={<Contacts />} />
        <Route path={AppRoutes.reports} element={<Reports />} />
        <Route path={AppRoutes.blockers} element={<Blockers />} />
        <Route path={AppRoutes.settings} element={<Settings />} />
        <Route path={AppRoutes.affiliates} element={<Affiliates />} />
        <Route path={AppRoutes.affiliateInfo} element={<AffiliateInfo />} />
        <Route path={AppRoutes.notifications} element={<Notifications />} />
        <Route path={AppRoutes.ads} element={<Ads />} />

        <Route path={AppRoutes.games}>
          <Route index element={<Games />} />
          <Route path={AppRoutes.gameQuestions} element={<GameQuestions />} />
        </Route>
      </Route>
      <Route path={AppRoutes.login} element={<Login />} />
      <Route path={AppRoutes.affiliateBase} element={<AffiliateLayout />}>
        <Route index element={<Affiliate />} />
        <Route path={AppRoutes.affiliateBase} element={<Affiliate />} />
      </Route>
      <Route path={AppRoutes.affiliateLogin} element={<AffiliateLogin />} />
    </Routes>
  );
}

export default App;
