import React from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import HeaderAffiliate from './components/Header';
import getPrefixCls from '../../utils/getPrefixCls';
import { RequireAuthAffiliate } from './components/RequireAuth';
import InitApplication from './init';
import Content from './components/Content';
import './index.css';

export default function AuthLayout() {
  return (
    <RequireAuthAffiliate>
      <Layout>
        <InitApplication />
        <HeaderAffiliate />
        <Layout className={getPrefixCls('layoutAffiliate')}>
          <Content>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </RequireAuthAffiliate>
  );
}
