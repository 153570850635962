import { acSetAffiliateMessage } from './index';
import { Globals } from '../../../types/globals';
import ApiRoutes from '../../../constants/apiRoutes';
import { openNotificationWithIcon } from '../../../utils/notification';

const acSetBesecretUsername =
  (besecret: string): Globals.ThunkAction =>
  async (dispatch, getState, { apiAffiliate }) => {
    acSetAffiliateMessage(undefined);

    const res = await apiAffiliate.post(ApiRoutes.setAffiliateBesecret, {
      besecret,
    });
    if (!res.hasError) {
      openNotificationWithIcon('success', 'Success', '');
    } else {
      openNotificationWithIcon('error', 'Something Went Wrong', '');
    }
  };

export default acSetBesecretUsername;
