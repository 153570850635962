import { Store } from 'redux';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export const baseURL = process.env.REACT_APP_BASE_URL as string;

interface ApiResponse {
  hasError: boolean;
  body: {
    data: any;
    blob?: any;
    name?: string;
    status: number;
    message: string;
    errorCode?: number;
  };
}

export class Api {
  private pStore: Store<Record<string, any>> | null = null;

  private readonly pAxiosInstance: AxiosInstance;

  public constructor() {
    this.pAxiosInstance = axios.create({
      baseURL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    this.pAxiosInstance.interceptors.response.use(
      (response) => response,
      (error) => Promise.reject(error)
    );
  }

  public get axiosInstance(): AxiosInstance {
    const accessToken = localStorage.getItem('access_token');

    if (accessToken) {
      this.pAxiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }

    return this.pAxiosInstance;
  }

  public set store(store: Store<Record<string, any>> | null) {
    this.pStore = store;
  }

  public get store(): Store<Record<string, any>> | null {
    return this.pStore;
  }

  public delete = async (url: string): Promise<ApiResponse> => {
    try {
      const response = await this.axiosInstance.delete(url);

      return {
        hasError: false,
        body: {
          status: response.status,
          message: response.data.message,
          data: response.data.data || {},
        },
      };
    } catch (e: any) {
      const { errors, status, message, errorCode } = e.response?.data || {};
      return {
        hasError: true,
        body: {
          errorCode,
          data: errors,
          name: e.name,
          message: message || e.message,
          status: status || e.response?.status,
        },
      };
    }
  };

  public put = async (
    url: string,
    data: Record<string, any> = {}
  ): Promise<ApiResponse> => {
    try {
      const response = await this.axiosInstance.put(url, data);

      return {
        hasError: false,
        body: {
          status: response.status,
          message: response.data.message,
          data: response.data.data || {},
        },
      };
    } catch (e: any) {
      const { errors, status, message, errorCode } = e.response?.data || {};
      return {
        hasError: true,
        body: {
          errorCode,
          data: errors,
          name: e.name,
          message: message || e.message,
          status: status || e.response?.status,
        },
      };
    }
  };

  public post = async (
    url: string,
    data: Record<string, any> = {},
    config: AxiosRequestConfig = {}
  ): Promise<ApiResponse> => {
    try {
      const response = await this.axiosInstance.post(url, data, config);

      return {
        hasError: false,
        body: {
          blob: response.data,
          status: response.status,
          message: response.data.message,
          data: response.data.data || response.data || {},
        },
      };
    } catch (e: any) {
      if (axios.isCancel(e)) {
        return {
          hasError: true,
          body: {
            data: {},
            name: 'AbortError',
            status: e.status || 500,
            message: 'Action is aborted.',
          },
        };
      }
      let message = 'Something went wrong';

      if (process.env.NODE_ENV !== 'production') {
        if (e.response?.data?.message) {
          message = e.response?.data?.message;
        } else if (e.response?.data?.error) {
          message = e.response?.data?.error;
        } else if (e.message) {
          message = e.message;
        }
      }

      return {
        hasError: true,
        body: {
          message,
          name: e.name,
          data: e.response.data || {},
          status: e.response?.data?.statusCode || e.response.status || 500,
        },
      };
    }
  };

  public get = async (
    url: string,
    {
      skipDelay,
      ...config
    }: AxiosRequestConfig & {
      skipDelay?: boolean;
    } = {
      skipDelay: false,
    }
  ): Promise<ApiResponse> => {
    try {
      const response = await this.axiosInstance.get(url, config);

      return {
        hasError: false,
        body: {
          status: response.status,
          message: response.data.message,
          data: response.data.data || response.data || {},
        },
      };
    } catch (e: any) {
      if (axios.isCancel(e)) {
        return {
          hasError: true,
          body: {
            data: {},
            message: '',
            status: 500,
            name: 'AbortError',
          },
        };
      }

      const { errors, status, message, errorCode } = e.response?.data || {};

      return {
        hasError: true,
        body: {
          errorCode,
          data: errors,
          name: e.name,
          message: message || e.message,
          status: status || e.response?.status,
        },
      };
    }
  };
}
