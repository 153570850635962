import React from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import Sider from './components/Sider';
import Header from './components/Header';
import Content from './components/Content';
import getPrefixCls from '../../utils/getPrefixCls';
import { RequireAuth } from './components/RequireAuth';
import InitApplication from './components/InitApplication';
import './index.css';

export default function AuthLayout() {
  return (
    <RequireAuth>
      <Layout>
        <InitApplication />
        <Header />
        <Layout hasSider className={getPrefixCls('layout-has-sider')}>
          <Sider />
          <Layout className={getPrefixCls('layout')}>
            <Content>
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </RequireAuth>
  );
}
