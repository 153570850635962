const AppRoutes = {
  base: '/',
  users: '/users',
  userInfo: '/users/:id/info',
  bots: '/bots',
  botQuestions: '/bots/:id/questions',
  games: '/games',
  gameQuestions: '/games/:id/questions',
  login: '/login',
  contacts: '/contacts',
  reports: '/reports',
  blockers: '/blockers',
  settings: '/settings',
  affiliates: '/affiliates',
  affiliateInfo: '/affiliates/:id/info/:username',
  affiliateBase: '/affiliate/home',
  affiliateLogin: '/affiliate/login',
  notifications: '/notifications',
  ads: '/ads',
};

export const AppRouteKeys = {
  [AppRoutes.base]: '1',
  [AppRoutes.users]: '2',
  [AppRoutes.bots]: '3',
  [AppRoutes.games]: '4',
  [AppRoutes.gameQuestions]: '4',
  [AppRoutes.contacts]: '5',
  [AppRoutes.reports]: '6',
  [AppRoutes.settings]: '7',
  [AppRoutes.affiliates]: '8',
  [AppRoutes.notifications]: '9',
  [AppRoutes.ads]: '10',
  [AppRoutes.blockers]: '11',
};

export default AppRoutes;
