import React from 'react';
import { Alert, Form, Input } from 'antd';
import { Controller } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import getPrefixCls from '../../utils/getPrefixCls';
import useLogin from './useLogin';
import { Forms } from '../../types/forms';
import Submit from './components/Submit';
import AppRoutes from '../../constants/appRoutes';
import './index.css';

export default function Login() {
  const {
    control,
    message,
    onSubmit,
    afterClose,
    isAuthenticated,
    formState: { isSubmitting },
  } = useLogin();

  if (isAuthenticated) {
    return <Navigate to={AppRoutes.users} replace />;
  }

  return (
    <form onSubmit={onSubmit}>
      <Form<Forms.Login>
        name="login"
        size="large"
        component="div"
        layout="vertical"
        autoComplete="off"
        className={getPrefixCls('login-page-form')}
      >
        {!!message?.message && (
          <Alert
            closable
            type={message?.type}
            afterClose={afterClose}
            message={message?.message}
            className={getPrefixCls('login-page-form-alert')}
          />
        )}
        <Controller
          name="username"
          control={control}
          render={({
            field: { ref, name, onChange, value, onBlur },
            fieldState: { error, invalid },
          }) => (
            <Form.Item
              name={name}
              label="Username"
              help={error?.message}
              validateStatus={invalid ? 'error' : undefined}
            >
              <Input
                ref={ref}
                autoFocus
                name={name}
                size="large"
                value={value}
                onBlur={onBlur}
                autoComplete="off"
                onChange={onChange}
              />
            </Form.Item>
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({
            field: { ref, name, onChange, value, onBlur },
            fieldState: { error, invalid },
          }) => (
            <Form.Item
              name={name}
              label="Password"
              help={error?.message}
              validateStatus={invalid ? 'error' : undefined}
            >
              <Input.Password
                ref={ref}
                name={name}
                size="large"
                value={value}
                onBlur={onBlur}
                autoComplete="off"
                onChange={onChange}
              />
            </Form.Item>
          )}
        />
        <Form.Item>
          <Submit control={control} isSubmitting={isSubmitting} />
        </Form.Item>
      </Form>
    </form>
  );
}
