import React from "react";
import { Button } from "antd";
import { Control, useWatch } from "react-hook-form";
import { Forms } from "../../../../types/forms";

export default function Submit({
  control,
  isSubmitting,
}: {
  control: Control<Forms.Login>;
  isSubmitting: boolean;
}) {
  const [username, password] = useWatch<Forms.Login>({
    control,
    name: ["username", "password"],
  });

  return (
    <Button
      block
      size="large"
      type="primary"
      htmlType="submit"
      loading={isSubmitting}
      disabled={!username || !password}
    >
      Login
    </Button>
  );
}
