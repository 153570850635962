import { acUpsertAuthAffiliate, acAffiliateLogout } from './index';
import { Globals } from '../../../types/globals';
import ApiRoutes from '../../../constants/apiRoutes';

const acMeAffiliate =
  (): Globals.ThunkAction =>
  async (dispatch, getState, { apiAffiliate }) => {
    const {
      hasError,
      body: { data },
    } = await apiAffiliate.get(ApiRoutes.meAffiliate);
    if (!hasError) {
      dispatch(acUpsertAuthAffiliate(data));
    } else {
      dispatch(acAffiliateLogout());
    }
  };

export default acMeAffiliate;
