import { createSelector } from 'reselect';
import getState from './index';

export const sAuth = createSelector(getState, (state) => state.auth);

export const sIsAuthenticated = createSelector(
  sAuth,
  (auth) => auth.isAuthenticated
);

export const sMessage = createSelector(sAuth, (auth) => auth.message);
export const sAuthUser = createSelector(sAuth, (auth) => auth.user);
export const sAccessToken = createSelector(sAuth, (auth) => auth.accessToken);
