import React from 'react';
import { Breadcrumb, Card, Spin, Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useUserInfo } from './useUserInfo';
import AppRoutes from '../../constants/appRoutes';

export default function UserInfo() {
  const { loading, data, columns, gamesSenderColumns, gamesSenderReceiver } =
    useUserInfo();

  if (loading) {
    return <Spin />;
  }

  return (
    <>
      <Card>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={AppRoutes.users}>Users</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{(data.user as any)?.username}</Breadcrumb.Item>
        </Breadcrumb>
      </Card>
      <br />
      <Card>
        <Typography.Title level={4}>Transactions</Typography.Title>
        <Table
          rowKey="id"
          loading={loading}
          columns={columns}
          pagination={false}
          scroll={{ x: true }}
          dataSource={data?.transactions || []}
        />
      </Card>
      <br />
      <Card>
        <Typography.Title level={4}>Games as Sender</Typography.Title>
        <Table
          rowKey="id"
          loading={loading}
          pagination={false}
          scroll={{ x: true }}
          columns={gamesSenderColumns}
          dataSource={data?.gamesSender || []}
        />
      </Card>
      <br />
      <Card>
        <Typography.Title level={4}>Games as Receiver</Typography.Title>
        <Table
          rowKey="id"
          loading={loading}
          pagination={false}
          scroll={{ x: true }}
          columns={gamesSenderReceiver}
          dataSource={data?.gamesReceiver || []}
        />
      </Card>
    </>
  );
}
