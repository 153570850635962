import produce from 'immer';

const InitialState: Record<string, any> = {};

const application = produce((draft, action) => {
  const { type } = action;

  switch (type) {
    case 'CoreActionTypes.LOGOUT': {
      return {
        ...InitialState,
      };
    }
    default:
      return undefined;
  }
}, InitialState);

export default application;
