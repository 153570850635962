import { acSetAffiliateAccessToken, acSetAffiliateMessage } from './index';
import { Globals } from '../../../types/globals';
import ApiRoutes from '../../../constants/apiRoutes';
import MessageTypes from '../../../constants/messageTypes';
import { Forms } from '../../../types/forms';

const acFetchAffiliateToken =
  (formValues: Forms.Login): Globals.ThunkAction =>
  async (dispatch, getState, { apiAffiliate }) => {
    acSetAffiliateMessage(undefined);

    const {
      hasError,
      body: { data, message },
    } = await apiAffiliate.post(ApiRoutes.affiliateLogin, formValues);

    if (hasError) {
      dispatch(
        acSetAffiliateMessage({
          message,
          type: MessageTypes.ERROR,
        })
      );
    } else {
      dispatch(acSetAffiliateAccessToken(data.access_token));
    }
  };

export default acFetchAffiliateToken;
