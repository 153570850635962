import { useSelector } from 'react-redux';
import { TablePaginationConfig, Tag } from 'antd';
import React from 'react';
import { useImmer } from 'use-immer';
import { stringify } from 'query-string';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import { Link, useParams } from 'react-router-dom';
import { CheckCircleOutlined } from '@ant-design/icons';
import { sAuthUser } from '../../store/selectors/auth';
import api from '../../service';
import ApiRoutes from '../../constants/apiRoutes';
import { FiniteStates } from '../../constants/finiteStates';
import { urlHelper } from '../../utils/urlHelper';
import AppRoutes from '../../constants/appRoutes';
import countries from '../../constants/contries.json';

export function useAffiliateInfo() {
  const authUser = useSelector(sAuthUser);
  const { id, username } = useParams();

  const [data, updateData] = useImmer({
    items: [],
    clicked: 0,
    meta: {
      totalPages: 0,
      totalItems: 0,
      itemCount: 10,
      currentPage: 1,
      itemsPerPage: 10,
    },
    sorter: {
      order: 'descend',
      field: 'created_at',
    },
    filters: {
      country: '',
      subId: '',
      dayFrom: dayjs().startOf('month').format('YYYY-MM-DD'),
      dayTo: '',
    },
    state: FiniteStates.IDLE,
    username,
  });

  const [columns] = useImmer<any[]>([
    {
      title: 'User',
      dataIndex: 'userId',
      key: 'userId',
      render: (value: any) => (
        <Link to={urlHelper(AppRoutes.userInfo, { id: value })}>{value}</Link>
      ),
    },
    {
      title: 'IP',
      dataIndex: 'ip',
      key: 'ip',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      render: (value: string) =>
        countries.find((val) => value === val.code)?.name,
    },
    {
      title: 'Registered',
      dataIndex: 'registered',
      key: 'registered',
      render: () => (
        <Tag icon={<CheckCircleOutlined />} color="success">
          Success
        </Tag>
      ),
    },
    {
      title: 'SubId',
      dataIndex: 'subId',
      key: 'subId',
      ellipsis: { showTitle: true },
    },
    {
      title: 'Date',
      dataIndex: 'updatedAt',
      ellipsis: { showTitle: true },
      key: 'updatedAt',
      sorter: true,
      render: (value: any) =>
        value ? dayjs(value).format('YYYY-MM-DD HH:MM') : '',
    },
  ]);

  React.useEffect(() => {
    (async () => {
      try {
        updateData((draft) => {
          draft.state = FiniteStates.LOADING;
        });
        let res: any;

        let order: any = {};
        if ((data.sorter as any).order === 'descend') {
          order = {
            order: 'DESC',
            field: (data.sorter as any).field,
          };
        } else if ((data.sorter as any).order === 'ascend') {
          order = {
            order: 'ASC',
            field: (data.sorter as any).field,
          };
        }
        const params = {
          ...data.meta,
          country: data.filters.country,
          subId: data.filters.subId,
          dayFrom: data.filters.dayFrom,
          dayTo: data.filters.dayTo,
          ...order,
        };

        res = await api.get(
          `${urlHelper(ApiRoutes.getAffiliateInfo, { id })}?${stringify(
            params
          )}`
        );
        res = {
          ...res,
          data: {
            ...res.data,
            items: res.data.items,
            clicked: res.data.clicked,
          },
        };

        updateData((draft) => {
          draft.meta = res.data.meta;
          draft.items = res.data.items;
          draft.clicked = res.data.clicked;
          draft.state = FiniteStates.SUCCESS;
        });
      } catch (e) {
        updateData((draft) => {
          draft.state = FiniteStates.FAILURE;
        });
      }
    })();
  }, [
    data.sorter,
    data.filters,
    data.meta.currentPage,
    data.meta.itemsPerPage,
  ]);

  function onChange(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) {
    if (sorter) {
      updateData((draft) => {
        draft.sorter = {
          order: (sorter as any).order,
          field: (sorter as any).field,
        };
      });
    }

    updateData((draft) => {
      draft.filters.country = (filters?.country as any) || '';
      draft.filters.subId = (filters?.subId as any) || '';
      draft.filters.dayFrom = (filters?.dayFrom as any) || '';
      draft.filters.dayTo = (filters?.dayTo as any) || '';
    });
  }

  return {
    data,
    columns,
    authUser,
    onChange,
    updateData,
    loading: [FiniteStates.IDLE, FiniteStates.LOADING].includes(data.state),
  };
}
