import React from 'react';
import {
  Alert,
  Button,
  Card,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Select,
  Table,
  Upload,
} from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useAds } from './useAds';
import { s3PublicURL } from '../../constants';
import countries from '../../constants/contries.json';

const { Option } = Select;

export default function Ads() {
  const {
    data,
    columns,
    loading,
    handleOk,
    showModal,
    updateData,
    handleCancel,
  } = useAds();

  return (
    <Card>
      <div style={{ marginBottom: 16 }}>
        <Button onClick={showModal} icon={<PlusOutlined />}>
          Create Ad
        </Button>
        {data.open && (
          <Modal
            title="Title"
            okText="Save"
            onOk={handleOk}
            open={data.open}
            maskClosable={false}
            onCancel={handleCancel}
            confirmLoading={data.confirmLoading}
          >
            {data.errors &&
              ((data.errors as any).messages || (data.errors as any).error) && (
                <>
                  <Alert
                    showIcon
                    closable
                    type="error"
                    message={(data.errors as any).error}
                    afterClose={() =>
                      updateData((draft) => {
                        draft.errors = undefined;
                      })
                    }
                    description={
                      <ul>
                        {(data.errors as any).messages.map(
                          (value: string, index: number) => (
                            <li key={index}>{value}</li>
                          )
                        )}
                      </ul>
                    }
                  />
                  <br />
                </>
              )}

            <Form component="div" layout="vertical">
              <Form.Item label="Text">
                <Input
                  value={data.ad.text}
                  onChange={(e) => {
                    updateData((draft) => {
                      draft.ad.text = e.target.value;
                    });
                  }}
                />
              </Form.Item>

              <Form.Item label="Link">
                <Input
                  value={data.ad.link}
                  onChange={(e) => {
                    updateData((draft) => {
                      draft.ad.link = e.target.value;
                    });
                  }}
                />
              </Form.Item>

              <Form.Item label="Countries">
                <Select
                  mode="multiple"
                  onChange={(value) => {
                    updateData((draft) => {
                      draft.ad.countries = value;
                    });
                  }}
                  value={data.ad.countries || []}
                >
                  {countries.map((value) => (
                    <Option key={value.code}>{value.name}</Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="From Date">
                <DatePicker
                  format="YYYY-MM-DD"
                  onChange={(date, str) => {
                    updateData((draft) => {
                      draft.ad.from = str;
                    });
                  }}
                  value={data.ad.from ? moment(data.ad.from) : null}
                />
              </Form.Item>

              <Form.Item label="Until Date">
                <DatePicker
                  format="YYYY-MM-DD"
                  onChange={(date, str) => {
                    updateData((draft) => {
                      draft.ad.to = str;
                    });
                  }}
                  value={data.ad.to ? moment(data.ad.to) : null}
                />
              </Form.Item>

              {!data?.ad?.id && (
                <Form.Item label="Image">
                  <Upload
                    name="image"
                    defaultFileList={[]}
                    multiple={false}
                    maxCount={1}
                    listType="picture-card"
                    accept=".png,.jpeg,.jpg"
                    beforeUpload={() => false}
                    onChange={(file) => {
                      updateData((draft) => {
                        draft.ad.image = file.fileList.length
                          ? file.fileList[0].originFileObj
                          : '';
                      });
                    }}
                  >
                    <Button icon={<UploadOutlined />} />
                  </Upload>
                  {data.ad.image && typeof data.ad.image === 'string' && (
                    <Image src={s3PublicURL + data.ad.image} width={60} />
                  )}
                </Form.Item>
              )}
            </Form>
          </Modal>
        )}
      </div>
      <Table
        rowKey="id"
        loading={loading}
        columns={columns}
        scroll={{ x: true }}
        dataSource={data?.items || []}
      />
    </Card>
  );
}
