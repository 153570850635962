import React from 'react';
import { Alert, Button, Card, Form, Input, Modal, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useAffiliates } from './useAffiliates';

export default function Affiliates() {
  const {
    data,
    columns,
    loading,
    handleOk,
    onChange,
    showModal,
    updateData,
    handleCancel,
  } = useAffiliates();

  return (
    <Card>
      <div style={{ marginBottom: 16 }}>
        <Button onClick={showModal} icon={<PlusOutlined />}>
          Create Affiliate
        </Button>
        {data.open && (
          <Modal
            title="Title"
            okText="Save"
            onOk={handleOk}
            open={data.open}
            maskClosable={false}
            onCancel={handleCancel}
            confirmLoading={data.confirmLoading}
          >
            {data.errors &&
              ((data.errors as any).messages || (data.errors as any).error) && (
                <>
                  <Alert
                    showIcon
                    closable
                    type="error"
                    message={(data.errors as any).error}
                    afterClose={() =>
                      updateData((draft) => {
                        draft.errors = undefined;
                      })
                    }
                    description={
                      <ul>
                        {(data.errors as any).messages.map(
                          (value: string, index: number) => (
                            <li key={index}>{value}</li>
                          )
                        )}
                      </ul>
                    }
                  />
                  <br />
                </>
              )}

            <Form component="div" layout="vertical">
              <Form.Item label="Username">
                <Input
                  disabled={!!data.affiliate.id}
                  value={data.affiliate.username}
                  onChange={(e) => {
                    updateData((draft) => {
                      draft.affiliate.username = e.target.value;
                    });
                  }}
                />
              </Form.Item>

              <Form.Item label="Password">
                <Input
                  value={data.affiliate.password}
                  onChange={(e) => {
                    updateData((draft) => {
                      draft.affiliate.password = e.target.value;
                    });
                  }}
                />
              </Form.Item>

              <Form.Item label="Random Percentage">
                <Input
                  value={data.affiliate.randomPercentage}
                  type="number"
                  min={1}
                  max={100}
                  onChange={(e) => {
                    updateData((draft) => {
                      draft.affiliate.randomPercentage = Number(e.target.value);
                    });
                  }}
                />
              </Form.Item>
            </Form>
          </Modal>
        )}
      </div>
      <Table
        rowKey="id"
        loading={loading}
        columns={columns}
        scroll={{ x: true }}
        pagination={{
          total: data.meta.totalItems,
          current: data.meta.currentPage,
          pageSize: data.meta.itemsPerPage,
          onChange: (page, pageSize) => {
            updateData((draft) => {
              draft.meta.currentPage = page;
              draft.meta.itemsPerPage = pageSize;
            });
          },
        }}
        dataSource={data?.items || []}
        onChange={onChange}
      />
    </Card>
  );
}
