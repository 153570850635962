import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import acFetchToken from "../../store/actions/auth/fetchToken";
import {sIsAuthenticated, sMessage} from "../../store/selectors/auth";
import {acSetMessage} from "../../store/actions/auth";
import {Forms} from "../../types/forms";

const defaultValues: Partial<Forms.Login> = {
  username: process.env.REACT_APP_USERNAME,
  password: process.env.REACT_APP_PASSWORD,
};
export default function useLogin() {
  const {handleSubmit, ...formRest} = useForm<Forms.Login>({
    mode: "onSubmit",
    defaultValues,
  });

  const dispatch = useDispatch();
  const message = useSelector(sMessage);
  const isAuthenticated = useSelector(sIsAuthenticated);

  function afterClose() {
    dispatch(acSetMessage(undefined));
  }

  const submit = React.useCallback(
    (formValues: any) => dispatch(acFetchToken(formValues) as any),
    [dispatch]
  );

  return {onSubmit: handleSubmit(submit), afterClose, isAuthenticated, message, ...formRest};
}
